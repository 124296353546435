import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  calcualteShopMaxDistance,
  calculateShopChargeTax,
  clearCart,
  placeOrder,
} from "../../../Redux/Store/cartReducer";
import { getUserProfile, logoutUser } from "../../../Redux/Store/userReducer";
import Lottie from 'react-lottie';
import loadingLottie from '../../../assets/others/track-order-loading.json'
import axios from "axios";

function ManagePayment() {
  const dispatch = useDispatch();
  const cartProducts = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user.user_data);

  const [paymentType, setPaymentType] = useState(null);
  const [notselected, setNotselected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rzLoading, setRzLoading] = useState(true);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [isScheduled, setIsScheduled] = useState(localStorage.getItem('is_schedule') == 'true' ? true : false);
  let schedule_date = localStorage.getItem('is_schedule') == 'true' ? localStorage.getItem("schedule_date") : null;
  let schedule_time = localStorage.getItem('is_schedule') == 'true' ? localStorage.getItem("schedule_time") : null;

  useEffect(() => {
    const formData = new FormData();
    formData.append("shop_id", cartProducts.shop_id);
    formData.append("latitude", localStorage.getItem("userLat"));
    formData.append("longitude", localStorage.getItem("userLng"));
    // dispatch(getShopDistance(formData)) 
    const formData2 = new FormData();
    const cartItems = JSON.stringify(cartProducts.orderitems)
    formData2.append("id", localStorage.getItem('user_id'));
    formData2.append("shop_id", cartProducts.shop_id);
    formData2.append("products", cartItems);
    formData2.append("latitude", localStorage.getItem("userLat"));
    formData2.append("longitude", localStorage.getItem("userLng"));
    dispatch(calcualteShopMaxDistance(formData2));
    const formData3 = new FormData();
    formData3.append("products", cartItems);
    dispatch(calculateShopChargeTax(formData3))
    const formData4 = new FormData();
    formData4.append("user_id", localStorage.getItem('user_id'));
    formData4.append("token", localStorage.getItem('device_token'));
    dispatch(getUserProfile(formData4)).then((response) => {
      if (response && response.payload && response.payload.success) {
        setTokenExpired(false)
        setLoading(false)
      } else {
        setTokenExpired(true)
      }
    });
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
		script.src = 'https://checkout.razorpay.com/v1/checkout.js';
		script.onload = () => {
			setRzLoading(false)
		};
		document.body.appendChild(script);
  }, []);

  const totalPrice = () => {
    let discount = 0;
    if (cartProducts?.appliedCoupon) {
      if (cartProducts?.appliedCoupon?.discount_type === "amount") {
        discount += parseFloat(cartProducts?.appliedCoupon?.used_amount);
      } else {
        discount += parseFloat(cartProducts?.totalAmount * parseFloat(cartProducts?.appliedCoupon?.used_amount) / 100);
        let max_discount = parseFloat(cartProducts?.appliedCoupon?.upto);
        if (max_discount > 0 && max_discount <= discount) {
          discount = parseFloat(cartProducts?.appliedCoupon?.upto);
        }
      }
    }

    let tax = 0;
    let surge_charge = 0;
    if (cartProducts?.shop_tax?.success) {
      tax = cartProducts?.shop_tax?.tax > 0 ? ((parseFloat(cartProducts?.totalAmount) * parseFloat(cartProducts?.shop_tax?.tax)) / 100) : 0;
      surge_charge = cartProducts?.shop_tax?.surge_charge;
    }
    let tip = 0;
    if (localStorage.getItem("tip") && parseFloat(localStorage.getItem("tip")) > 0) {
      tip = parseFloat(localStorage.getItem("tip"));
    } else {
      tip = 0;
    }

    let packing_charge = 0;
    if (cartProducts?.shop_distance?.shop_id?.packing_charge && cartProducts?.shop_distance?.shop_id?.packing_charge > 0) {
      packing_charge = cartProducts?.shop_distance?.shop_id?.packing_charge;
    }

    let delivery_charge = 0;
    if (cartProducts?.shop_distance?.shop_id?.free_delivery && cartProducts?.shop_distance?.shop_id?.free_delivery_subtotal <= cartProducts.totalAmount) {
      delivery_charge = 0;
    } else {
      delivery_charge = parseFloat(cartProducts.deliveryCharge);
    }

    if (discount > 0) {
      return (parseFloat(cartProducts.totalAmount + parseFloat(tip)) - parseFloat(discount) + parseFloat(tax) + parseFloat(surge_charge) + parseFloat(delivery_charge) + parseFloat(packing_charge)).toFixed(2)
    } else {
      return (parseFloat(cartProducts.totalAmount + parseFloat(tip)) + parseFloat(tax) + parseFloat(surge_charge) + parseFloat(delivery_charge) + parseFloat(packing_charge)).toFixed(2)
    }
  }

  const _completeOrder = (e) => {
    if (tokenExpired) {
      localStorage.removeItem("user_id")
      localStorage.removeItem("userLogin")
      localStorage.removeItem("auth_token")
      localStorage.removeItem("is_location")
      localStorage.removeItem("address")
      localStorage.removeItem("geoLocation")
      localStorage.removeItem("userSetAddress")
      localStorage.removeItem("userLng")
      localStorage.removeItem("userLat")
      dispatch(logoutUser())
      window.location.replace("/login")
    } else if (paymentType == null) {
      setNotselected(true)
    } else {
      setLoading(true)
      e.preventDefault();
      const formData = new FormData();
      let cartProduct = JSON.stringify(cartProducts?.orderitems);
      let defaultAddress = localStorage.getItem("userSetAddress");
      formData.append("user_id", localStorage.getItem("user_id"));
      formData.append("token", localStorage.getItem("device_token"));
      formData.append("paymentMode", paymentType);
      formData.append("code", cartProducts?.appliedCoupon?.coupon_code ?? null);
      formData.append("cartProducts", cartProduct);
      formData.append("subtotal", cartProducts?.totalAmount);
      let discount = 0;
      if (cartProducts?.appliedCoupon?.discount_type === "FIXED") {
        discount += parseFloat(cartProducts?.discount);
      } else {
        discount += parseFloat(
          (cartProducts?.totalAmount * cartProducts?.discount) / 100
        );
        let max_discount = parseFloat(cartProducts?.appliedCoupon?.max_discount);
        if (max_discount > 0 && max_discount <= discount) {
          discount = parseFloat(cartProducts?.appliedCoupon?.max_discount);
        }
      }
      formData.append("discount", discount);
      formData.append("distance", cartProducts?.shop_distance?.distance ?? 0);
      formData.append("address", defaultAddress);
      formData.append("surge_charge", parseFloat(cartProducts?.shop_tax?.surge_charge) ?? 0);
      formData.append("tax", ((parseFloat(cartProducts?.totalAmount) * parseFloat(cartProducts?.shop_tax?.tax)) / 100) ?? 0);
      formData.append("store_id", cartProducts?.shop_id);
      formData.append("is_schedule", isScheduled);
      formData.append("schedule_date", schedule_date);
      formData.append("schedule_time", schedule_time);
      formData.append("tip", localStorage.getItem("tip"));
      let totalPrices = totalPrice();
      formData.append("totalPayableAmount", totalPrices);
      dispatch(placeOrder(formData)).then((response) => {
        if (response && response.payload && response.payload.success) {
          if (response.payload?.data?.payment_mode == 'COD') {
            localStorage.removeItem("tip");
            dispatch(clearCart());

            window.location.replace("/order-success/" + response.payload.data.id);
          } else {
            launchRazor(response?.payload?.data, sendOrderCompleteResponse, successRazor);
          }
        } else {
          setLoading(false)
          console.log("error");
        }
      });
    }
  };

  const launchRazor = (order, runFunction, successRazor) => {
		axios.post('https://apps.deleevro.com/api/process-razorpay', {
			amount: parseFloat(order.total).toFixed(2),
			id: order.id,
			token: localStorage.getItem("device_token")
		})
			.then((res) => {
				if (res.data.razorpay_success) {
					// if (!window.ReactNativeWebView) {
						const options = {
							key: 'rzp_live_mdlFIANCE4L2CH',
							amount: parseFloat(order.total).toFixed(2),
							name: 'Deleevro',
							currency: 'INR',
							order_id: res.data.response.id,
							payment_capture: 1,
							method: {
								netbanking: true,
								card: true,
								wallet: false,
								upi: true
							},

							handler(response) {
								console.log("Final Response", response);
								runFunction(order.id, response.razorpay_payment_id, response.razorpay_order_id, response.razorpay_signature);
								successRazor(order.id);
							},
							modal: {
								ondismiss: function () {
									console.log("closed");
									runFunction(order.id, null, null, null);
                  setLoading(false);
								},
								confirm_close: true
							},
							prefill: {
								name: user?.data?.name,
								contact: user?.data?.phone,
								email: user?.data?.email,
							},

						};
						const rzp1 = new window.Razorpay(options);
						rzp1.open();
					// } else {
					// 	// Its from ReactNativeWebView .. Hence Launching Native RazorPay
					// 	const options = {
					// 		key: 'rzp_live_qiEWGfEpXiaboK',
					// 		amount: parseFloat(order.payable).toFixed(2),
					// 		name: 'Chopze Delivery',
					// 		currency: 'INR',
					// 		order_id: res.data.response.id,
					// 		payment_capture: 1,
					// 		method: {
					// 			netbanking: true,
					// 			card: true,
					// 			wallet: false,
					// 			upi: true
					// 		},
					// 		prefill: {
					// 			name: this.props.user.data.name,
					// 			contact: this.props.user.data.phone,
					// 			email: this.props.user.data.email ? this.props.user.data.email : '',
					// 		},
					// 		modal: {
					// 			ondismiss: function () {
					// 				console.log("closed");
					// 				runFunction(order.id, null, null, null);
					// 			},
					// 			confirm_close: true
					// 		},
					// 	};

					// 	const response = {
					// 		type: 'razor_pay_initiate',
					// 		data: {
					// 			options: options,
					// 			order_id: order.id,
					// 			auth_token: this.props.user.data.auth_token
					// 		}
					// 	};

					// 	window.ReactNativeWebView.postMessage(JSON.stringify(response));

					// }
				}
			});
	}

  const sendOrderCompleteResponse = (id, payment_id, order_id, signature) => {
		axios.post('https://apps.deleevro.com/api/process-razorpay-state-update', {
			id: id,
			token: localStorage.getItem("device_token"),
			payment_id,
			order_id,
			signature
		});
	};

  const successRazor = (id) => {
    localStorage.removeItem("tip");
    dispatch(clearCart());

		window.location.replace("/order-success/" + id);
	}

  const itemCount = () => {
    let count = 0

    if (cartProducts?.orderitems?.length > 0) {
      cartProducts?.orderitems?.map((orderItem) => {
        if (orderItem?.selectedaddons?.length > 0) {
          count += orderItem.selectedaddons.length;
        } else {
          count += 1;
        }
      })
      return parseInt(count);
    } else {
      return count;
    }
  }

  const loadingLott = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div>
      {/* <!-- header start --> */}
      <header className="section-t-space">
        <div className="custom-container">
          <div className="d-flex align-items-center">
            <div onClick={() => { window.history.back() }}>
              <i className="ri-arrow-left-s-line fs-5"></i>
            </div>
            <h2 className="flex-grow-1 text-center" style={{ fontWeight: '600' }}>Payment Options</h2>
          </div>
        </div>
      </header>
      {/* <!-- header end --> */}

      {/* <!-- payment method section start --> */}
      {rzLoading || loading ?
        <div className="flex items-center justify-center" style={{ paddingTop: '15rem' }}>
          <Lottie
            options={loadingLott}
            height={100}
            width={100}
          />
        </div>
        :
        <section className="payment method section-lg-b-space">
          <div className="custom-container">
            {/* <h3 className="fw-semibold dark-text">Credit / Debit Card</h3> */}
            {/* <div className="payment-list">
            <ul className="cart-add-box payment-card-box gap-0 mt-3">
              <li className="w-100">
                <div className="payment-detail">
                  <div className="add-img">
                    <img
                      className="img-fluid img"
                      src="assets/images/icons/svg/mastercard.svg"
                      alt="mastercard"
                    />
                  </div>
                  <div className="add-content">
                    <div>
                      <h5 className="fw-semibold dark-text">
                        Mastercard **** **** 4589
                      </h5>
                      <h6 className="mt-1 light-text">Expires on 16/24</h6>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        checked
                      />
                    </div>
                  </div>
                </div>
              </li>

              <li className="new-card w-100">
                <a href="#" className="payment-detail">
                  <div className="add-img">
                    <i className="ri-add-line theme-color add-square-img"></i>
                  </div>
                  <div className="add-content">
                    <div>
                      <h5 className="fw-semibold dark-text">Add New Card</h5>
                      <h6 className="mt-1 light-text">Save and Pay via Cards.</h6>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div> */}

            {/* <h3 className="fw-semibold dark-text section-t-space">
            Credit / Debit Card
          </h3> */}

            <ul className="payment-list section-lg-b-space">
              {/* <li className="cart-add-box payment-card-box gap-0 mt-3">
              <div className="payment-detail">
                <div className="add-img">
                  <img
                    className="img-fluid img"
                    src="assets/images/icons/svg/paypal.svg"
                    alt="mastercard"
                  />
                </div>
                <div className="add-content">
                  <div>
                    <h5 className="fw-semibold">Pay Pal</h5>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="pay_pal"
                      onClick={()=>{setPaymentType('PAYPAL')}}
                    />
                  </div>
                </div>
              </div>
            </li>
            <li className="cart-add-box payment-card-box gap-0 mt-3">
              <div className="payment-detail">
                <div className="add-img">
                  <img
                    className="img-fluid img img1"
                    src="assets/images/icons/svg/apple-pay.svg"
                    alt="apple-pay"
                  />
                </div>
                <div className="add-content">
                  <div>
                    <h5 className="fw-semibold">Apple Pay</h5>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                    />
                  </div>
                </div>
              </div>
            </li> */}
              <li className="cart-add-box payment-card-box gap-0 mt-3">
                <div className="payment-detail" onClick={() => { paymentType === 'ONLINE' ? setPaymentType(null) : setPaymentType('ONLINE') }}>
                  <div className="add-img">
                    <img
                      className="img-fluid img"
                      src="assets/images/icons/svg/mastercard.svg"
                      alt="google-pay"
                    />
                  </div>
                  <div className="add-content">
                    <div>
                      <h5 className="fw-semibold">Online</h5>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="online"

                        checked={paymentType === 'ONLINE' ? true : false}
                      />
                    </div>
                  </div>
                </div>
              </li>
              <li className="cart-add-box payment-card-box gap-0 mt-3">
                <div className="payment-detail" onClick={() => { paymentType === 'COD' ? setPaymentType(null) : setPaymentType('COD') }}>
                  <div className="add-img">
                    <img
                      className="img-fluid img"
                      src="assets/images/icons/svg/cash.svg"
                      alt="cash"
                    />
                  </div>
                  <div className="add-content">
                    <div>
                      <h5 className="fw-semibold">Cash on Delivery</h5>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="cod"
                        checked={paymentType === 'COD' ? true : false}
                      />
                    </div>
                  </div>
                </div>
              </li>
              {notselected ?
                <div className="text-center mt-3" style={{ color: 'red' }}>
                  Select payment type
                </div> : null}
            </ul>

            <div class="pay-popup">
              <div class="price-items">
                <h3>₹ {totalPrice()}</h3>
                <h6>{itemCount()} item Added</h6>
              </div>
              <div class="btn theme-btn pay-btn mt-0" onClick={(e) => _completeOrder(e)}>Pay Now</div>
            </div>
          </div>
        </section>
      }
      {/* <!-- payment method section end --  */}
    </div>
  );
}

export default ManagePayment;

//onClick={(e) => { _completeOrder(e) }}
