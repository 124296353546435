// import React from 'react'
import { useState } from "react"
import ProgressiveImage from "react-progressive-image";
import no_image from '../../assets/images/rp1.png'
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { FaMinus, FaPlus } from "react-icons/fa";
import { SwipeableDrawer } from "@material-ui/core";
import { HiOutlineMinus } from "react-icons/hi";
import { RiCloseCircleLine } from "react-icons/ri";
import { addCustomizationCart, clearCart } from '../../Redux/Store/cartReducer';


const Customization = ({ product, addProducts, storeList, cartPage, cardView }) => {
    const dispatch = useDispatch();
    const [customizeOpen, setCustomizeOpen] = useState(false);
    const cartProducts = useSelector((state) => state.cart);
    const [quantity, setQuantity] = useState(1);
    const [otherStore, setOtherStore] = useState(false);
    const [stateItem, setStateItem] = useState(null);

    const addonoffer = (price) => {
        // console.log(price, 'price')
        if (product?.shop?.is_offer && parseFloat(product?.shop.offer_percentage) > 0) {
            return parseFloat(parseFloat(price) - parseFloat(price) * parseFloat(product?.shop?.offer_percentage) / 100).toFixed(1)
        } else {
            return parseFloat(price)
        }
    }
    const _processAddons = (item) => {
        let addons = [];
        addons["selectedaddons"] = [];
        const index = cartProducts.orderitems.findIndex((p) => p.id === item.id);
        let radio = document.querySelectorAll("input[type=radio]:checked");
        let checkboxes = document.querySelectorAll("input[type=checkbox]:checked");

        if (index > -1) {
            cartProducts.orderitems.forEach((cp) => {
                if (cp.id === item.id && cp.selectedaddons?.length > 0) {
                    for (let i = 0; i < radio.length; i++) {
                        cp.selectedaddons.map((cd) => {
                            if (cd.addon_id == radio[i].getAttribute("data-addon-id")) {
                                addons["selectedaddons"].push({
                                    addon_category_name: radio[i].name,
                                    addon_id: parseInt(radio[i].getAttribute("data-addon-id")),
                                    addon_name: radio[i].getAttribute("data-addon-name"),
                                    quantity: quantity,
                                    type: "SINGLE",
                                    price: addonoffer(parseFloat(radio[i].value)),
                                    itemPrice: addonoffer(item.selling_price),
                                    singlePrice: parseFloat(addonoffer(item.selling_price)) + parseFloat(addonoffer(parseFloat(radio[i].value))),
                                    totalPrice: (parseFloat(addonoffer(item.selling_price)) + parseFloat(addonoffer(parseFloat(radio[i].value))) * quantity),
                                });
                            } else {
                                addons["selectedaddons"].push({
                                    addon_category_name: radio[i].name,
                                    addon_id: parseInt(radio[i].getAttribute("data-addon-id")),
                                    addon_name: radio[i].getAttribute("data-addon-name"),
                                    quantity: quantity,
                                    type: "SINGLE",
                                    price: addonoffer(parseFloat(radio[i].value)),
                                    itemPrice: addonoffer(item.selling_price),
                                    singlePrice: parseFloat(addonoffer(item.selling_price)) + parseFloat(addonoffer(parseFloat(radio[i].value))),
                                    totalPrice: (addonoffer(item.selling_price) * quantity) + (parseFloat(addonoffer(parseFloat(radio[i].value))) * quantity),
                                });
                            }
                            return cd;
                        })
                    }

                    for (let i = 0; i < checkboxes.length; i++) {
                        cp.selectedaddons.map((cd) => {
                            if (cd.addon_id == checkboxes[i].getAttribute("data-addon-id")) {
                                addons["selectedaddons"].push({
                                    addon_category_name: checkboxes[i].name,
                                    addon_id: parseInt(checkboxes[i].getAttribute("data-addon-id")),
                                    addon_name: checkboxes[i].getAttribute("data-addon-name"),
                                    quantity: quantity,
                                    type: "MULTIPLE",
                                    price: addonoffer(parseFloat(checkboxes[i].value)),
                                    itemPrice: addonoffer(item.selling_price),
                                    singlePrice: parseFloat(addonoffer(item.selling_price)) + parseFloat(addonoffer(parseFloat(checkboxes[i].value))),
                                    totalPrice: (addonoffer(item.selling_price) * quantity) + (parseFloat(addonoffer(parseFloat(checkboxes[i].value))) * quantity),
                                });
                            } else {
                                addons["selectedaddons"].push({
                                    addon_category_name: checkboxes[i].name,
                                    addon_id: parseInt(checkboxes[i].getAttribute("data-addon-id")),
                                    addon_name: checkboxes[i].getAttribute("data-addon-name"),
                                    quantity: quantity,
                                    type: "MULTIPLE",
                                    price: addonoffer(parseFloat(checkboxes[i].value)),
                                    itemPrice: addonoffer(item.selling_price),
                                    singlePrice: parseFloat(addonoffer(item.selling_price)) + parseFloat(addonoffer(parseFloat(checkboxes[i].value))),
                                    totalPrice: (addonoffer(item.selling_price) * quantity) + (parseFloat(addonoffer(parseFloat(checkboxes[i].value))) * quantity),
                                });
                            }
                            return cd;
                        })
                    }
                } else {
                    for (let i = 0; i < radio.length; i++) {
                        addons["selectedaddons"].push({
                            addon_category_name: radio[i].name,
                            addon_id: parseInt(radio[i].getAttribute("data-addon-id")),
                            addon_name: radio[i].getAttribute("data-addon-name"),
                            quantity: quantity,
                            type: "SINGLE",
                            price: addonoffer(parseFloat(radio[i].value)),
                            itemPrice: addonoffer(item.selling_price),
                            singlePrice: parseFloat(addonoffer(item.selling_price)) + parseFloat(addonoffer(parseFloat(radio[i].value))),
                            totalPrice: (addonoffer(item.selling_price) * quantity) + (parseFloat(addonoffer(parseFloat(radio[i].value))) * quantity),
                        });
                        item.stock = radio[i].getAttribute("data-addon-stock");
                    }

                    for (let i = 0; i < checkboxes.length; i++) {
                        addons["selectedaddons"].push({
                            addon_category_name: checkboxes[i].name,
                            addon_id: parseInt(checkboxes[i].getAttribute("data-addon-id")),
                            addon_name: checkboxes[i].getAttribute("data-addon-name"),
                            quantity: quantity,
                            type: "MULTIPLE",
                            price: addonoffer(parseFloat(checkboxes[i].value)),
                            itemPrice: addonoffer(item.selling_price),
                            singlePrice: parseFloat(addonoffer(item.selling_price)) + parseFloat(addonoffer(parseFloat(checkboxes[i].value))),
                            totalPrice: (addonoffer(item.selling_price) * quantity) + (parseFloat(addonoffer(parseFloat(checkboxes[i].value))) * quantity),
                        });
                        item.stock = checkboxes[i].getAttribute("data-addon-stock");
                    }
                }
            });
        } else {
            for (let i = 0; i < radio.length; i++) {
                addons["selectedaddons"].push({
                    addon_category_name: radio[i].name,
                    addon_id: parseInt(radio[i].getAttribute("data-addon-id")),
                    addon_name: radio[i].getAttribute("data-addon-name"),
                    quantity: quantity,
                    type: "SINGLE",
                    price: addonoffer(parseFloat(radio[i].value)),
                    singlePrice: parseFloat(addonoffer(item.selling_price)) + parseFloat(addonoffer(parseFloat(radio[i].value))),
                    itemPrice: addonoffer(item.selling_price),
                    totalPrice: (addonoffer(item.selling_price) * quantity) + (parseFloat(addonoffer(parseFloat(radio[i].value))) * quantity),
                });
                item.stock = radio[i].getAttribute("data-addon-stock");
            }

            for (let i = 0; i < checkboxes.length; i++) {
                addons["selectedaddons"].push({
                    addon_category_name: checkboxes[i].name,
                    addon_id: parseInt(checkboxes[i].getAttribute("data-addon-id")),
                    addon_name: checkboxes[i].getAttribute("data-addon-name"),
                    quantity: quantity,
                    type: "MULTIPLE",
                    price: addonoffer(parseFloat(checkboxes[i].value)),
                    singlePrice: parseFloat(addonoffer(item.selling_price)) + parseFloat(addonoffer(parseFloat(checkboxes[i].value))),
                    itemPrice: addonoffer(item.selling_price),
                    totalPrice: (addonoffer(item.selling_price) * quantity) + (parseFloat(addonoffer(parseFloat(checkboxes[i].value))) * quantity),
                });
                item.stock = checkboxes[i].getAttribute("data-addon-stock");
            }
        }

        let newData = Object.assign({}, item, addons);
        if (cartProducts.orderitems.length == 0 || newData.store_id === cartProducts?.store_id) {
            if (addons && addons?.selectedaddons && addons?.selectedaddons?.length > 0) {
                dispatch(addCustomizationCart(newData));
            }
        } else {
            setOtherStore(true);
            if (addons && addons?.selectedaddons && addons?.selectedaddons?.length > 0) {
                setStateItem(newData)
            }
        }
        setQuantity(1)

    };

    const onMinus = () => {
        if (quantity === 1) {
            setCustomizeOpen(false)
        } else {
            let qty;
            qty = quantity - 1;
            setQuantity(qty)
        }
    }

    const onPlus = () => {
        let qty;
        qty = quantity + 1;
        setQuantity(qty)
    }
    return (
        <div>
            {storeList ? (
                <>
                    <div class="add-btn" onClick={() => setCustomizeOpen(true)} >
                        <span class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal" style={{ borderRadius: '8px' }}><i
                            class="ri-add-line"></i> ADD</span>
                    </div>
                </>
            ) : cartPage ? (
                <>
                    <div className="wishlist-item-add-round-btn" onClick={() => setCustomizeOpen(true)} >
                        <FaPlus />
                    </div>
                </>
            ) : cardView ? (
                <div className="cart mb-0" onClick={() => setCustomizeOpen(true)}>
                    <i className="ri-shopping-cart-2-fill"></i>
                </div>
            ) : cartProducts.orderitems.find((cp) => cp.id === product?.id) !== undefined ? (
                <Link to="/bag">
                    <div className='store-to-cart-btn'>
                        To Cart
                    </div>
                </Link>
            ) : (
                <div className="store-item-add-btn" onClick={() => setCustomizeOpen(true)}  >  Add
                </div>
            )}
            <SwipeableDrawer
                anchor="bottom"
                open={customizeOpen}
                onClose={() => setCustomizeOpen(false)}
                onOpen={() => setCustomizeOpen(true)}
                swipeAreaWidth={20}
                transitionDuration={500}
                style={customizeOpen ? { zIndex: '99999999' } : {}}
            >
                <div className="d-flex align-items-center justify-content-center w-100">
                    <HiOutlineMinus size={35} color={"#00000024"} />
                </div>
                <div className="" style={{ paddingBottom: "7rem", }}>
                    <div className='d-flex justify-content-between align-items-center px-3 pb-2 '>
                        <div className="font-22 fw-700" style={{ color: '#070648', }}>
                            Customize
                        </div>
                        <div onClick={() => { setCustomizeOpen(false) }}>
                            <RiCloseCircleLine size={25} />
                        </div>
                    </div>
                    {cartPage ?
                        <div className='position-fixed bottom-0 w-100 py-4 px-3 d-flex bg-white'>
                            <div className="d-flex justify-content-between align-items-center me-3" >
                                <div className="store-item-remove-round-btn p-2.5" onClick={() => { onMinus() }} >
                                    <FaMinus />
                                </div>
                                <span className='px-3'>
                                    {quantity}
                                </span>
                                <div className="store-item-add-round-btn p-2.5" onClick={() => { onPlus() }}>
                                    <FaPlus />
                                </div>
                            </div>
                            <div className='customize-add-cart-btn text-center' onClick={() => {
                                let cp = { ...product, quantity: quantity };
                                _processAddons(cp);
                                setCustomizeOpen(false);
                            }}>
                                Add to cart
                            </div>
                        </div>
                        :
                        <>
                            <div className='p-3'>
                                {product && product?.addoncategories && product?.addoncategories?.filter((cp) => cp?.addons?.length > 0)?.map((addon_category) => (
                                    <div key={addon_category.id} className="addon-category-block">
                                        <div className="mt-2 w-100 p-1 text-dark"
                                            style={{ fontWeight: '600', fontSize: '18px' }}>
                                            {addon_category.name} ({addon_category && addon_category?.addons && addon_category?.addons?.length + ' Addons'})
                                        </div>
                                        {addon_category && addon_category?.addons && addon_category?.addons?.length > 0 && (
                                            <div className="mt-2" key={addon_category.id}>
                                                {addon_category && addon_category?.addons && addon_category?.addons?.map((addon, index) => (
                                                    <div key={addon.id}>
                                                        <label htmlFor={addon.name} className="d-flex justify-content-between py-2">
                                                            <div className='d-flex align-items-center whitespace-nowrap py-1'>
                                                                <input type={addon_category.type === 'SINGLE' ? "radio" : "checkbox"}
                                                                    className="check-cust"
                                                                    name={addon_category.name}
                                                                    data-addon-id={addon.id}
                                                                    data-addon-stock={addon.stock}
                                                                    data-addon-name={addon.name}
                                                                    id={addon.name}
                                                                    value={parseFloat(addon.price)}
                                                                    defaultChecked={
                                                                        // addon_category.type === "SINGLE" &&
                                                                        index === 0 &&
                                                                        true
                                                                    }
                                                                />
                                                                {addon_category.type === "SINGLE" && (
                                                                    <label className='ms-2 fw-550 text-slate-400' htmlFor={addon.name} />
                                                                )}

                                                                <label className="ms-2 fw-550 text-slate-400 addon-label mx-2" htmlFor={addon.name}   >
                                                                    {addon.name}
                                                                </label>
                                                                <label className="ms-2 fw-550 text-slate-400 addon-price d-flex justify-content-end">
                                                                    {/* ₹  {addon.price} */}
                                                                    {product?.shop?.is_offer && parseFloat(product?.shop.offer_percentage) > 0 ? (
                                                                        <h6 class="fw-semibold">
                                                                            <span> ₹{parseFloat(parseFloat(addon.price) - parseFloat(addon.price) * parseFloat(product?.shop?.offer_percentage) / 100).toFixed(1)}</span>
                                                                            {" "}/  <s style={{ textDecorationColor: 'red' }}>₹{parseFloat(addon.price).toFixed(1)}</s>
                                                                            <span style={{ marginLeft: '5px', fontWeight: '600', fontSize: '13px', color: '#7ac356' }}>{parseFloat(product?.shop?.offer_percentage).toFixed(2)}% off</span>
                                                                        </h6>
                                                                    ) : (
                                                                        <h6 class="fw-semibold"><span>₹{parseFloat(addon.price).toFixed(1)}</span></h6>
                                                                    )}
                                                                </label>
                                                            </div>
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <hr style={{ color: '#c7c7c7', margin: '0px' }} />
                            {/* <div className="font-18 fw-700 px-3 pt-3" style={{ color: '#070648' }}>
                                Add-ons
                            </div>
                            <div className="grid grid-cols-5 overflow-x-scroll gap-2 hidden-scroll-bar py-3 px-3 "   >
                                <div className="single-product-items-box">
                                    <div>
                                        <ProgressiveImage
                                            delay={20}
                                            src={biriyani}
                                             placeholder={no_image}
                                        >
                                            {(src) => (
                                                <img
                                                    src={src}
                                                    style={{
                                                        height: "28vw",
                                                        width: "100%",
                                                        borderRadius: "8px",
                                                        objectFit: "cover",
                                                    }}
                                                    className="" alt="Limtzo"
                                                />
                                            )}
                                        </ProgressiveImage>

                                        <div className="mt-1 text-black fw-600 font-15">
                                             <LinesEllipsis
                                text={"Mayonise"}
                                maxLine='1'
                                ellipsis='...'
                                trimRight
                                basedOn='letters'
                            />
                                        </div>
                                    </div>
                                    <div className="flex justify-between items-center" >
                                        <div className="fw-700 font-17" style={{ color: '#fcc314' }}>
                                            ₹240
                                        </div>
                                        <div className="store-item-add-btn" >
                                            Add
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                        </>}
                    <div className='position-fixed bottom-0 w-100 py-4 px-3 d-flex bg-white'>
                        <div className="d-flex justify-content-between align-items-center me-3" >
                            <div className="store-item-remove-round-btn p-2.5" onClick={() => { onMinus() }} >
                                <FaMinus />
                            </div>
                            <span className='px-3'>
                                {quantity}
                            </span>
                            <div className="store-item-add-round-btn p-2.5" onClick={() => { onPlus() }}>
                                <FaPlus />
                            </div>
                        </div>
                        <div className='customize-add-cart-btn text-center' onClick={() => {
                            let cp = { ...product, quantity: quantity };
                            _processAddons(cp);
                            setCustomizeOpen(false);
                        }}>
                            Add to cart
                        </div>
                    </div>
                </div>
            </SwipeableDrawer>

            <SwipeableDrawer
                anchor="bottom"
                open={otherStore}
                onClose={() => { setOtherStore(false); setCustomizeOpen(true) }}
                swipeAreaWidth={20}
                transitionDuration={500}
                style={otherStore ? { zIndex: '99999999' } : {}}
            >
                <div className="flex items-center justify-center w-100">
                    <HiOutlineMinus size={35} color={"#00000024"} />
                </div>
                <div className="" style={{ paddingBottom: "4rem", }}>
                    <div className='flex justify-between items-center px-3 pb-2 '>
                        <div className="font-22 fw-700" style={{ color: '#070648', }}>
                            <h1 className="mt-2 mb-0 font-weight-black h5">
                                Replace items in the cart?
                            </h1>
                        </div>
                        <div onClick={() => { setOtherStore(false); setCustomizeOpen(true) }}>
                            <RiCloseCircleLine size={25} />
                        </div>
                    </div>
                    <div>
                        <div className="px-3 col-12 pb-3 d-flex justify-content-between align-items-center">
                        </div>
                        <div className="px-3 mb-20">
                            Your cart contains items from other
                            store. Do you want to discard the
                            selection and add items from this store?
                        </div>
                        <div className="d-flex justify-content-around w-100 px-3 mt-3 fixed bottom-3">
                            <div className="text-center mr-4 w-100">
                                <button
                                    className="single-product-add-btn py-3 px-2 w-100 relative"
                                    onClick={() => { setOtherStore(false); setCustomizeOpen(true) }}
                                >
                                    NO
                                </button>
                            </div>
                            <div className="text-center w-100">
                                <button
                                    className="single-product-yes py-3 px-2 w-100 text-center text-white position-relative"
                                    onClick={() => {
                                        dispatch(clearCart())
                                        setOtherStore(false)
                                        dispatch(addCustomizationCart(stateItem));
                                    }}
                                >
                                    YES
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </SwipeableDrawer>
        </div>
    )
}

export default Customization
