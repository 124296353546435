import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios'
import { WEBSITE_API_URL } from "../../config";



const initialState = {
  home_data: [],
  single_slider: [],
  single_category: [],
  isSuccess: false,
  message: "",
  loading: false,
};

export const getAllHomeDatas = createAsyncThunk(
  "store/getAllHomeDatas",
  async (formData) => {
    try {
      const response = await axios.post(
        WEBSITE_API_URL + "/get-all-home-datas",
        formData
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);

export const getSingleSlider = createAsyncThunk(
  "store/getSingleSlider",
  async (formData) => {
    try {
      const response = await axios.post(
        WEBSITE_API_URL + "/get-single-slider",
        formData
      );
      if (response.data.success) {
        return response.data.data;
      }
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);

export const getSingleCategory = createAsyncThunk(
  "store/getSingleCategory",
  async (formData) => {
    try {
      const response = await axios.post(
        WEBSITE_API_URL + "/get-single-categories",
        formData
      );
      if (response.data.success) {
        return response.data.data;
      }
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    homeCurrentTenant: (state, action) => {
      state.tenant = action.payload;
    },
  },
  extraReducers: {
    [getAllHomeDatas.pending]: (state,) => {
        state.loading = true;
    },
    [getAllHomeDatas.fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.home_data = payload;
        state.isSuccess = true;
    },
    [getAllHomeDatas.rejected]: (state, { payload }) => {
        state.message = payload;
        state.loading = false;
        state.isSuccess = false;
    },

    [getSingleSlider.pending]: (state,) => {
        state.loading = true;
    },
    [getSingleSlider.fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.single_slider = payload;
        state.isSuccess = true;
    },
    [getSingleSlider.rejected]: (state, { payload }) => {
        state.message = payload;
        state.loading = false;
        state.isSuccess = false;
    },
    [getSingleCategory.pending]: (state,) => {
        state.loading = true;
    },
    [getSingleCategory.fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.single_category = payload;
        state.isSuccess = true;
    },
    [getSingleCategory.rejected]: (state, { payload }) => {
        state.message = payload;
        state.loading = false;
        state.isSuccess = false;
    },

  },
});

export const { homeCurrentTenant } = homeSlice.actions;

export default homeSlice.reducer;