import { useEffect } from 'react'
import success from '../../../assets/images/success.png';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearCart } from '../../../Redux/Store/cartReducer';

function OrderSuccess() {
    const dispatch = useDispatch();

    let { id } = useParams();

    useEffect(() => {
        dispatch(clearCart())
        setTimeout(() => {
            localStorage.removeItem("tip")
            localStorage.removeItem("scheduledDate")
            localStorage.removeItem("scheduledTime")
            window.location.replace("/order-history")
        }, 3000);
    }, []);

    return (
        <div >
            {/* <BackButton /> */}
            <div style={{ paddingTop: '9rem' }}>
                <div className="order-success-center px-3">
                    <img src={success} alt='limitzo' />
                    <div className='mt-4 font-24 fw-600 order-success-text'>
                        Order Placed Successfully!!
                    </div>
                    {/* <div className='fw-550'>
                        You have Earned a Scratch Card
                    </div> */}
                </div>
            </div>
            <div className="fixed-btn pt-2 pb-4 px-3 bg-white" >
                {/* <Link to={"/track-order/" + id} >
                    <div className='get-start-button'>Track Order</div>
                </Link> */}
                <Link to="/order-history" >
                    <div className='get-start-button'>View Orders</div>
                </Link>
            </div>
        </div>
    )
}

export default OrderSuccess;