import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { WEBSITE_API_URL } from "../../config";


const initialState = {
  user_data: [],
  user_edit: [],
  user_address: [],
  isSuccess: false,
  message: "",
  loading: false,
};

export const sendOtp = createAsyncThunk("store/sendOtp", async (formData) => {
  try {
    const response = await axios.post(WEBSITE_API_URL + "/send-otp", formData);
    return response.data;
  } catch (error) {
    console.log("error", error);
    throw error;
  }
});

export const verifyOtp = createAsyncThunk(
  "store/verifyOtp",
  async (formData) => {
    try {
      const response = await axios.post(
        WEBSITE_API_URL + "/verify-otp",
        formData
      );
      if (response.data.success && response.data.already_existing) {
        localStorage.setItem("user_id", response.data.data.id);
        localStorage.setItem("device_token", response.data.data.device_token);

        const persist = {
          user: response.data.data
        };
        if (window.ReactNativeWebView) {
          const response = {
            type: 'state',
            data: persist
          };
          window.ReactNativeWebView.postMessage(JSON.stringify(response));
        }
      }
      return response.data;
    } catch (error) {
      console.log("error2", error);
      throw error;
    }
  }
);

export const userRegister = createAsyncThunk(
  "store/userRegister",
  async (formData) => {
    try {
      const response = await axios.post(
        WEBSITE_API_URL + "/user-register",
        formData
      );
      if (response.data.success) {
        localStorage.setItem("user_id", response.data.data.id);
        localStorage.setItem("device_token", response.data.data.device_token);

        const persist = {
          user: response.data.data
        };
        if (window.ReactNativeWebView) {
          const response = {
            type: 'state',
            data: persist
          };
          window.ReactNativeWebView.postMessage(JSON.stringify(response));
        }
      }
      return response.data;
    } catch (error) {
      console.log("error2", error);
      throw error;
    }
  }
);

export const getUserProfile = createAsyncThunk("store/getUserProfile",
  async (formData) => {
    try {
      const response = await axios.post(
        WEBSITE_API_URL + "/get-user-profile",
        formData
      );
      if (response.data.success) {
        localStorage.setItem("user_id", response.data.data.id);
        localStorage.setItem("device_token", response.data.data.device_token);
      }
      return response.data;
    } catch (error) {
      console.log("error2", error);
      throw error;
    }
  }
);

export const getUserData = createAsyncThunk(
  "store/getUserData",
  async (formData) => {
    try {
      const response = await axios.post(
        WEBSITE_API_URL + "/get-user-data",
        formData
      );
      return response.data;
    } catch (error) {
      console.log("error2", error);
      throw error;
    }
  }
);

export const updateUserData = createAsyncThunk(
  "store/updateUserData",
  async (formData) => {
    try {
      const response = await axios.post(
        WEBSITE_API_URL + "/update-user-data",
        formData
      );
      return response.data;
    } catch (error) {
      console.log("error2", error);
      throw error;
    }
  }
);

export const logoutUser = createAsyncThunk("store/logoutUser", () => {
  try {
    const data = null;
    localStorage.removeItem("user_id")
    localStorage.removeItem("userLogin")
    localStorage.removeItem("auth_token")
    localStorage.removeItem("is_location")
    localStorage.removeItem("address")
    localStorage.removeItem("geoLocation")
    localStorage.removeItem("userSetAddress")
    localStorage.removeItem("userLng")
    localStorage.removeItem("userLat")
    return data;
  } catch (error) {
    console.log("error3", error);
    throw error;
  }
});

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    userCurrentTenant: (state, action) => {
      state.tenant = action.payload;
    },
    logoutUser: (state) => {
      state.user_data = [];
      state.user_edit = [];
      state.user_address = [];
      state.user_order = [];
      state.single_order = {};
      state.isSuccess = false;
      state.contact = "";
    },
  },
  extraReducers: {
    [sendOtp.pending]: (state) => {
      state.loading = true;
    },
    [sendOtp.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.user_data = payload;
      state.isSuccess = true;
    },
    [sendOtp.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },

    // login
    [verifyOtp.pending]: (state) => {
      state.loading = true;
    },
    [verifyOtp.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.user_data = payload;
      state.isSuccess = true;
    },
    [verifyOtp.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    // user register
    [userRegister.pending]: (state) => {
      state.loading = true;
    },
    [userRegister.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.user_data = payload;
      state.isSuccess = true;
    },
    [userRegister.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },

    // user profile
    [getUserProfile.pending]: (state) => {
      state.loading = true;
    },
    [getUserProfile.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.user_data = payload;
      state.isSuccess = true;
    },
    [getUserProfile.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },

    [updateUserData.pending]: (state) => {
      state.loading = true;
    },
    [updateUserData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.user_data = payload;
      state.isSuccess = true;
    },
    [updateUserData.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },

    [getUserData.pending]: (state) => {
      state.loading = true;
    },
    [getUserData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.user_edit = payload;
      state.isSuccess = true;
    },
    [getUserData.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
  },
});
export const { userCurrentTenant } = userSlice.actions;

export default userSlice.reducer;
