import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCdTsWgrRSzrA1RRKmvwM4vyXdL3WExzwk",
    authDomain: "deleevro-c7b38.firebaseapp.com",
    projectId: "deleevro-c7b38",
    storageBucket: "deleevro-c7b38.appspot.com",
    messagingSenderId: "602687427546",
    appId: "1:602687427546:web:3d549cdfb8b0cc885f66e3"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);