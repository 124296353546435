import ProgressiveImage from 'react-progressive-image';
import { Link } from 'react-router-dom';
import { IMAGE_BASE_URL } from '../../../config';
import no_image from '../../../assets/images/rp1.png'

function GroceryCategories({ groceryCategories }) {

    return (
        <div>
            {groceryCategories && groceryCategories?.length > 0 ?
                <section className="section-t-space">
                    <div className="title px-3">
                        <h3 className="mt-0">Shop by category</h3>
                    </div>
                    <div className='custom-container'>
                        <div className="d-grid gap-2 hidden-scroll-bar" style={{ gridTemplateColumns: "repeat(4 ,1fr)", overflowX: 'hidden' }}   >
                            {groceryCategories?.map((category) => (
                                <Link to={'/single-category/GROCERY/' + category?.id}>
                                    <div className='text-center'>
                                        <div>
                                            <ProgressiveImage
                                                delay={20}
                                                src={IMAGE_BASE_URL + category.image}
                                                placeholder={no_image}
                                            >
                                                {(src) => (
                                                    <img
                                                        src={src}
                                                        style={{
                                                            width: "20vw", minWidth: "20vw", height: "20vw", "objectFit": "cover", "borderRadius": "5px", marginTop: '18px'
                                                        }}
                                                        className=""
                                                        alt="zeato"
                                                    />
                                                )}
                                            </ProgressiveImage>
                                        </div>
                                        <h6 className='mt-2'>{category?.name}</h6>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </section>
                : null}
        </div>
    );
}

export default GroceryCategories