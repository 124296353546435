import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import Lottie from 'react-lottie';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { updateUserData } from '../../../Redux/Store/userReducer';
import load from '../../../assets/others/track-order-loading.json';
import ProgressiveImage from 'react-progressive-image';
import no_image from '../../../assets/images/rp1.png'

function ProfileSetting() {

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user_data?.data);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(user?.name ? user?.name : null);
  const [mobile, setMobile] = useState(user?.mobile ? user?.mobile : null);
  const [email, setEmail] = useState(user?.email ? user?.email : null);

  const updateProfile = (e) => {
    e.preventDefault();
    setLoading(true)
    const formData = new FormData();
    formData.append("user_id", user?.id);
    formData.append("token", localStorage.getItem('device_token'));
    formData.append("name", name);
    formData.append("mobile", mobile);
    formData.append("email", email);
    dispatch(updateUserData(formData)).then((response) => {
      if (response && response.payload && response.payload.success) {
        setLoading(false)
      } else {
        setLoading(false)
      }
    })
  }

  const defaultOptions = {
    animationData: load,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      {loading ?
        <div style={{ paddingTop: "35vh" }}>
          <Lottie
            options={defaultOptions}
            height={100}
            width={100}
          />
        </div>
        :
        <div>


          {/* <!-- header start --> */}
          <header className="section-t-space">
            <div className="custom-container">
              <div className="header-panel">
                <Link to={'/profile'}>
                  <i className="ri-arrow-left-s-line"></i>
                </Link>
                <h2>Profile Setting</h2>
              </div>
            </div>
          </header>

          <section>
            <div className="custom-container">
              <div className="profile-img">
                <ProgressiveImage
                  delay={20}
                  src="assets/images/icons/profile.png"
                  // src={IMAGE_BASE_URL + store.image}
                  placeholder={no_image}
                >
                  {(src) => (
                    <img
                      src={src}
                      alt="deleevro"
                      className="img-fluid img"
                    />
                  )}
                </ProgressiveImage>
              </div>
            </div>
          </section>

          <section className="section-b-space">
            <div className="custom-container">
              <Form onSubmit={(e) => updateProfile(e)}>
                <div className="form-group">
                  <label className="form-label fw-semibold">My Name</label>
                  <div className="form-input mb-4">
                    <input type="text" className="form-control" value={name} placeholder="Enter your name" onChange={(e) => setName(e.target.value)} />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label fw-semibold">mobile Number</label>
                  <div className="form-input mb-4">
                    <input type="text" className="form-control" onChange={(e) => setMobile(e.target.value)} value={mobile} placeholder="Enter mobile number" />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label fw-semibold">Email</label>
                  <div className="form-input mb-4">
                    <input type="text" className="form-control" onChange={(e) => setEmail(e.target.value)} value={email} placeholder="Enter email" />
                  </div>
                </div>
                <button type='sumbit' className="btn theme-btn save-address w-100">Save </button>
              </Form>
            </div>
          </section>
        </div>
      }
    </div>
  )
}

export default ProfileSetting