import React, { useState } from 'react';
import { AiFillSmile } from 'react-icons/ai';
import Ink from 'react-ink';
 
const Tip = ({ addTip, tip }) => {
    return (
        <React.Fragment>
            <section class="delivery address">
                <div class="custom-container">
                    <h3 class="fw-semibold dark-text">Tip Your Delivery Guy</h3>
                    <div className='mt-3'>
                        <div style={{ color: '#b0b0b0' }} >Thank your delivery partner for helping you stay safe indoors.
                        </div>
                        <div className='d-flex flex-row justify-content-between align-items-center pt-3'>
                            <div className={tip === "10" ? 'cart-tip-active p-1 position-relative' : 'cart-tip p-1 position-relative'} onClick={() => addTip('10')}>
                                Rs 10 <AiFillSmile size="1.2em" />
                                <Ink duration={500} style={{ color: "#aaa" }} />
                            </div>
                            <div className={tip === "20" ? 'cart-tip-active p-1 position-relative' : 'cart-tip p-1 position-relative'} onClick={() => addTip('20')}>
                                Rs 20 <AiFillSmile size="1.2em" />
                                <Ink duration={500} style={{ color: "#aaa" }} />
                            </div>
                            <div className={tip === "50" ? 'cart-tip-active p-1 position-relative' : 'cart-tip p-1 position-relative'} onClick={() => addTip('50')}>
                                Rs 50 <AiFillSmile size="1.2em" />
                                <Ink duration={500} style={{ color: "#aaa" }} />
                            </div>
                            <div className={tip === "100" ? 'cart-tip-active p-1 position-relative' : 'cart-tip p-1 position-relative'} onClick={() => addTip('100')}>
                                Rs 100 <AiFillSmile size="1.2em" />
                                <Ink duration={500} style={{ color: "#aaa" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Tip
