import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { WEBSITE_API_URL } from "../../config";

const initialState = {
    favorite_items: [],
    isSuccess: false,
    message: "",
    loading: false,
};

export const addToFavorite = createAsyncThunk("store/addToFavorite", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/add-favorite", formData);
        if (response.data.success) {
            return response.data.data;
        }
    } catch (error) {
        console.log('error', error);
        throw error;
    }
});

export const removeFromFavorite = createAsyncThunk("store/removeFromFavorite", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/remove-favorite", formData);
        if (response.data.success) {
            return response.data.data;
        }
    } catch (error) {
        console.log('error', error);
        throw error;
    }
});

export const toggleFavItem = createAsyncThunk("store/toggleFavItem", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/toggle-favorite", formData);
        if (response.data.success) {
            return response.data.data;
        }
    } catch (error) {
        console.log('error', error);
        throw error;
    }
});

export const getAllFavoriteItems = createAsyncThunk("store/getAllFavoriteItems", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/get-all-favorite-items", formData);
        if (response.data.success) {
            return response.data;
        }
    } catch (error) {
        console.log('error', error);
        throw error;
    }
});

const itemSlice = createSlice({
    name: "item",
    initialState: initialState,
    reducers: {
        storeCurrentTenant: (state, action) => {
            state.tenant = action.payload;
        },
    },
    extraReducers: {
        [getAllFavoriteItems.pending]: (state,) => {
            state.loading = true;
        },
        [getAllFavoriteItems.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.favorite_items = payload;
            state.isSuccess = true;
        },
        [getAllFavoriteItems.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },
    },
});


export const { storeCurrentTenant } = itemSlice.actions;

export default itemSlice.reducer;
