
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { addToCart, clearCart } from '../../../Redux/Store/cartReducer';
import { RiCloseCircleLine } from 'react-icons/ri';
import { SwipeableDrawer } from '@material-ui/core';
import { HiOutlineMinus } from 'react-icons/hi2'
import ListItem from './ListItemCategory';
import Collapsible from 'react-collapsible';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import food from '../../../assets/images/food.png';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';

function Items({ categories }) {
    const dispatch = useDispatch();
    const [otherStore, setOtherStore] = useState(false);
    const [open, setOpen] = useState(false);
    const [stateItem, setStateItem] = useState(null);
    const [openSections, setOpenSections] = useState({});
    const [searchKeyword, setSearchKeyword] = useState(null);

    const toggleCollapse = (categoryId) => {
        setOpenSections(prevState => ({
            ...prevState,
            [categoryId]: !prevState[categoryId]
        }));
    };

    const handleStateItem = (childState) => {
        // Update parent state when child state changes
        setStateItem(childState);
    };

    const handleOtherStore = (childState) => {
        // Update parent state when child state changes
        setOtherStore(childState);
    };

    const search = (value) => {
        if (value.length > 1) {
            setSearchKeyword(value)
        } else {
            setSearchKeyword(null)
        }
    }

    return (
        <div>
            <div style={{ position: 'fixed', width: '100vw', left: '0', bottom: '10vw', zIndex: '9999', display: 'flex', justifyContent: 'center' }}>
                <div onClick={() => setOpen(!open)} style={{ "backgroundColor": "rgb(122, 195, 86)", "zIndex": "9999", "padding": "10px 0px", "width": "24vw", "color": "#fff", "borderRadius": "10px", "display": "flex", "alignItems": "center", "justifyContent": "center" }}>
                    <span>Menu</span>
                    <img style={{ marginLeft: '5px' }} src={food} alt="food" />
                </div>
            </div>
            <Dialog
                id="basic-menu"
                anchorEl={''}
                open={open}
                onClose={() => setOpen(false)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {categories?.filter(cp => cp?.products?.length > 0).map((category, index) => (
                    <MenuItem onClick={() => {
                        document.getElementById(`section-${category?.id}`).scrollIntoView()
                        setOpen(false);
                    }} key={index} style={{ minWidth: '60vw', justifyContent: 'space-between' }}>
                        <div>{category?.name}</div>
                        <div>{category?.products.length}</div>
                    </MenuItem>
                ))}
            </Dialog>
            <section className="search-section">
                <div className="px-3">
                    <form className="auth-form search-head" target="_blank">
                        <div className="form-group">
                            <div className="form-input" style={{ width: '100%' }}>
                                <input onChange={(a) => search(a.target.value)} type="search" className="form-control search" placeholder="Search items..." />
                                <i className="ri-search-line search-icon"></i>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
            {searchKeyword ? (
                <div class="px-3 mt-4 pb-3">
                    {categories?.filter(cp => cp?.products?.length > 0).map((category, index) => (
                        <div key={index}>
                            {category?.products?.filter(cp => cp?.name.toLowerCase().includes(searchKeyword.toLowerCase())).map((product) => (
                                <ListItem product={product} onStateItemChange={handleStateItem} onOtherStoreChange={handleOtherStore} />
                            ))}
                            <hr style={{ margin: '0.5rem 0', opacity: '0.1', borderTop: '2px dashed #808080' }} />
                        </div>
                    ))}
                </div>
            ) : (
                <div class="px-3 mt-4 pb-3">
                    {categories?.filter(cp => cp?.products?.length > 0).map((category, index) => (
                        <div id={`section-${category?.id}`} key={index + category?.id}>
                            <Collapsible
                                trigger={
                                    <div className="d-flex align-items-center justify-content-between" onClick={() => toggleCollapse(category.id)}>
                                        <div className="font-bold text-lg " style={{ padding: '0.8rem 0px' }}>{category?.name} ({category?.products?.length})</div>
                                        {openSections[category.id] ? <MdKeyboardArrowUp size={30} /> : <MdKeyboardArrowDown size={30} />}
                                    </div>
                                }
                                open={openSections[category.id] ? openSections[category.id] : true}
                            >
                                {category?.products?.map((product) => (
                                    <ListItem product={product} onStateItemChange={handleStateItem} onOtherStoreChange={handleOtherStore} />
                                ))}
                                <hr style={{ margin: '0.5rem 0', opacity: '0.1', borderTop: '2px dashed #808080' }} />
                            </Collapsible>
                        </div>
                    ))}
                </div>
            )}
            <SwipeableDrawer
                anchor="bottom"
                open={otherStore}
                onClose={() => setOtherStore(false)}
                swipeAreaWidth={20}
                transitionDuration={500}
                style={otherStore ? { zIndex: '99999999' } : {}}
            >
                <div className="d-flex align-items-center justify-content-center w-100">
                    <HiOutlineMinus size={35} color={"#00000024"} />
                </div>
                <div className="" >
                    <div className='d-flex justify-content-between align-items-center px-3 pb-2 '>
                        <div className="font-22 fw-700" style={{ color: '#070648', }}>
                            <h1 className="mt-2 mb-0 font-weight-black h5">
                                Replace items in the cart?
                            </h1>
                        </div>
                        <div onClick={() => { setOtherStore(false) }}>
                            <RiCloseCircleLine size={25} />
                        </div>
                    </div>
                    <div>
                        <div className="px-3 col-12 pb-3 d-flex justify-content-between align-items-center">
                        </div>
                        <div className="px-3 mb-20">
                            Your cart contains items from other
                            store. Do you want to discard the
                            selection and add items from this store?
                        </div>
                        <div className="d-flex justify-content-around w-100 px-3 mt-5  mb-3">
                            <div className="text-center me-4 w-100">
                                <button
                                    className="single-product-no py-3 px-2 w-100 position-relative"
                                    onClick={() => { setOtherStore(false) }}
                                >
                                    NO
                                </button>
                            </div>
                            <div className="text-center w-100">
                                <button
                                    className="single-product-yes py-3 px-2 w-100 text-center text-white position-relative"
                                    onClick={() => {
                                        dispatch(clearCart())
                                        setOtherStore(false)
                                        dispatch(addToCart(stateItem));
                                    }}
                                >
                                    YES
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </SwipeableDrawer>
        </div>

    )
}

export default Items