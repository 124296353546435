import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Link } from 'react-router-dom';
import ProgressiveImage from 'react-progressive-image';
import { IMAGE_BASE_URL } from '../../../config';
import no_image from '../../../assets/images/rp1.png'

function Categories({ foodCategories }) {

    return (
        <div>
            {foodCategories && foodCategories?.length > 0 ?
                <section className="section-t-space">
                    <div className="title px-3">
                        <h3 className="mt-0">Food Categories</h3>
                        <a href="category-list">See All</a>
                    </div>
                    <div className='d-flex flex-row align-items-center'>
                        <div className="d-grid gap-3 hidden-scroll-bar px-3 pt-3" style={{ gridTemplateColumns: "repeat(6 ,1fr)", overflowX: 'scroll' }}   >
                            {foodCategories?.map((category, index) => (
                                <Link to={'/single-category/FOOD/' + category.id} key={index}>
                                    <div className='text-center'>
                                        <div>
                                            <ProgressiveImage
                                                delay={20}
                                                src={IMAGE_BASE_URL + category.image}
                                                placeholder={no_image}
                                            >
                                                {(src) => (
                                                    <img
                                                        src={src}
                                                        style={{
                                                            width: "17vw", minWidth: "17vw", height: "17vw", "objectFit": "cover", "borderRadius": "50%"
                                                        }}
                                                        alt="deleevro"
                                                    />
                                                )}
                                            </ProgressiveImage>
                                        </div>
                                        <h6 className='mt-2'>{category?.name}</h6>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </section>
                : null}
        </div>
    );
}

export default Categories