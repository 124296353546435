import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { WEBSITE_API_URL } from "../../config";

const initialState = {
    address_data: [],
    coordinate_data: [],
    address_edit: [],
    isSuccess: false,
    message: "",
    loading: false,
};

export const getAddressToCoordinates = createAsyncThunk("store/getAddressToCoordinates", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/get-address-to-coordinates", formData);

        return response.data;
    } catch (error) {
        console.log('error2', error);
        throw error;
    }
});

export const addNewAddress = createAsyncThunk("store/addNewAddress", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/add-new-address", formData);

        return response.data;
    } catch (error) {
        console.log('error2', error);
        throw error;
    }
});

export const createAddress = createAsyncThunk("store/createAddress", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/V1/user/address", formData);

        return response.data;
    } catch (error) {
        console.log('error2', error);
        throw error;
    }
});
export const setAddressDefault = createAsyncThunk("store/setAddressDefault", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/set-address-default", formData);

        return response.data;
    } catch (error) {
        console.log('error2', error);
        throw error;
    }
});


export const getAllAddress = createAsyncThunk("store/getAllAddress", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/get-all-address", formData);
        return response.data;
    } catch (error) {
        console.log('error2', error);
        throw error;
    }
});

export const getEditAddress = createAsyncThunk("store/getEditAddress", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/get-edit-address", formData);
        return response.data;
    } catch (error) {
        console.log('error2', error);
        throw error;
    }
});

export const updateAddress = createAsyncThunk("store/updateAddress", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/update-address", formData);
        return response.data;
    } catch (error) {
        console.log('error2', error);
        throw error;
    }
}); 

export const deleteAddress = createAsyncThunk("store/deleteAddress", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/delete-address", formData);
        return response.data;
    } catch (error) {
        console.log('error2', error);
        throw error;
    }
}); 
const addressSlice = createSlice({
    name: "address",
    initialState: initialState,
    reducers: {
        addressCurrentTenant: (state, action) => {
            state.tenant = action.payload;
        },
    },
    extraReducers: {
        // Address
        [createAddress.pending]: (state,) => {
            state.loading = true;
        },
        [createAddress.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.address_data = payload;
            state.isSuccess = true;
        },
        [createAddress.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },
        [setAddressDefault.pending]: (state,) => {
            state.loading = true;
        },
        [setAddressDefault.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.address_data = payload;
            state.isSuccess = true;
        },
        [setAddressDefault.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },


        [getAllAddress.pending]: (state,) => {
            state.loading = true;
        },
        [getAllAddress.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.address_data = payload.data;
            state.isSuccess = true;
        },
        [getAllAddress.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },

        [getEditAddress.pending]: (state,) => {
            state.loading = true;
        },
        [getEditAddress.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.address_edit = payload.data;
            state.isSuccess = true;
        },
        [getEditAddress.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },

        [updateAddress.pending]: (state,) => {
            state.loading = true;
        },
        [updateAddress.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.address_data = payload;
            state.isSuccess = true;
        },
        [updateAddress.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },

        [deleteAddress.pending]: (state,) => {
            state.loading = true;
        },
        [deleteAddress.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.address_data = payload;
            state.isSuccess = true;
        },
        [deleteAddress.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },

        [getAddressToCoordinates.pending]: (state,) => {
            state.loading = true;
        },
        [getAddressToCoordinates.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.coordinate_data = payload;
            state.isSuccess = true;
        },
        [getAddressToCoordinates.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },

    },
});


export const { addressCurrentTenant } = addressSlice.actions;

export default addressSlice.reducer;
