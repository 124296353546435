import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

function DeliveryAddress() {
    const dispatch = useDispatch();

    const defaultAddress = useSelector((state) => state.address.address_data.data);

    const changeAddress = () => {
        localStorage.setItem("IsBackCart", true)
        window.location.replace("/location")
    }
    return (
        <div>
            <section class="delivery address">
                <div class="custom-container bg-none">
                    <h3 class="fw-semibold dark-text">Delivery Address</h3>
                    <div class="cart-add-box mt-3 ">
                        <div class="add-img" style={{ minWidth: '4.6rem' }}>
                            <img class="img-fluid img" src="assets/images/home.png" alt="rp1" style={{ width: '4.5rem' }} />
                        </div>
                        <div class="flex-grow-1">
                            <div className='d-flex justify-content-between'>
                                <h5 class="fw-semibold dark-text">Deliver to : {JSON.parse(localStorage.getItem('userSetAddress')).tag}</h5>
                                <div style={{ color: '#7ac356', fontSize: '12px' }} onClick={() => { changeAddress() }}>Change</div>
                            </div>
                            <div class="address light-text mt-1 ellipsis w-100">
                                {JSON.parse(localStorage.getItem('userSetAddress')).address ?? ''}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default DeliveryAddress