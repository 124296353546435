import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addNewAddress, getEditAddress, updateAddress } from "../../Redux/Store/addressReducer";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import Lottie from "react-lottie";
import load from "../../assets/others/track-order-loading.json";


function AddressDetails() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("HOME");
  const [landmark, setLandmark] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [address, setAddress] = useState(localStorage.getItem("address") ? localStorage.getItem("address")?.replace('"', "") : null);


  useEffect(() => {

    if (localStorage.getItem("AddressEdit") == "true") {
      const formData = new FormData();
      formData.append("token", localStorage.getItem("device_token"));
      formData.append("user_id", localStorage.getItem("user_id"));
      formData.append("address_id", localStorage.getItem("address_id"));
      dispatch(getEditAddress(formData)).then((response) => {
        if (response && response.payload && response.payload.success) {
          setAddress(response?.payload?.address?.address)
          setLandmark(response?.payload?.address?.landmark)
          setType(response?.payload?.address?.address_type)
          setLatitude(response?.payload?.address?.latitude)
          setLongitude(response?.payload?.address?.longitude)
          setLoading(false)
        } else {
          setLoading(false)
        }
      })
    } else {
      setAddress(localStorage.getItem("address") ? localStorage.getItem("address")?.replace('"', "") : null)
      setLoading(false)
    }
  }, []);

  const handleSetDefaultAddress = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem('user_id'));
    formData.append("token", localStorage.getItem('device_token'));
    formData.append("address", address);
    formData.append("landmark", landmark);
    formData.append("latitude", localStorage.getItem("userLat"));
    formData.append("longitude", localStorage.getItem("userLng"));
    formData.append("addressType", type);

    dispatch(addNewAddress(formData)).then((response) => {
      if (response && response.payload && response.payload.success) {
        const userSetAddress = {
          latitude: response.payload.data.defaultAddress.latitude,
          longitude: response.payload.data.defaultAddress.longitude,
          address: response.payload.data.defaultAddress.address,
          landmark: response.payload.data.defaultAddress.landmark,
          tag: response.payload.data.defaultAddress.address_type,
        };
        localStorage.setItem("userLat", response.payload.data.defaultAddress.latitude);
        localStorage.setItem("userLng", response.payload.data.defaultAddress.longitude);
        localStorage.setItem("address", response.payload.data.defaultAddress.address);
        localStorage.setItem("userSetAddress", JSON.stringify(userSetAddress));
        if (localStorage.getItem("AddressEdit") == "true" || localStorage.getItem("AddressBack") == "true") {
          localStorage.removeItem("AddressEdit")
          localStorage.removeItem("AddressBack")
          window.location.replace("/manage-delivery-address")
        } else {
          window.location.replace("/home")
        }
      }
    })
  };

  const handleUpdateAddress = (e) => {
    e.preventDefault();
    setLoading(true)
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem('user_id'));
    formData.append("token", localStorage.getItem('device_token'));
    formData.append("address_id", localStorage.getItem("address_id"));
    formData.append("address", address);
    formData.append("address", address);
    formData.append("landmark", landmark);
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    formData.append("addressType", type);
    dispatch(updateAddress(formData)).then((response) => {
      if (response && response.payload && response.payload.success) {
        if (localStorage.getItem("AddressEdit") == "true") {
          localStorage.removeItem("AddressEdit")
          window.location.replace("/manage-delivery-address")
        }
      }
    })
  };

  const defaultOptions = {
    animationData: load,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div style={{ minHeight: '100vh' }}>
      {loading ?
        <div className="flex items-center justify-center" style={{ paddingTop: '15rem' }}>
          <Lottie
            options={defaultOptions}
            height={100}
            width={100}
          />
        </div>
        :
        <div>

          <header className="section-t-space">
            <div className="custom-container">
              <div className="header-panel">
                {localStorage.getItem("AddressEdit") == "true" ?
                  <Link to="/manage-delivery-address">
                    <i className="ri-arrow-left-s-line"></i>
                  </Link>
                  :
                  <Link to="/set-location">
                    <i className="ri-arrow-left-s-line"></i>
                  </Link>
                }
                <h2>Complete Address</h2>
              </div>
            </div>
          </header>

          <section className="food-filter address-filter">
            <div className="custom-container">
              <h3>Save As</h3>
              <ul className="food-symbol">
                <li>
                  <div className={type === "HOME" ? "food-types px-2 py-2 active" : "food-types px-2 py-2"} onClick={() => setType("HOME")}>
                    <i className="ri-home-5-fill light-text"></i>
                    <h5 className="light-text">Home</h5>
                  </div>
                </li>
                <li>
                  <div className={type === "WORK" ? "food-types px-2 py-2 active" : "food-types px-2 py-2"} onClick={() => setType("WORK")}>
                    <i className="ri-briefcase-4-fill light-text"></i>
                    <h5 className="light-text">Work</h5>
                  </div>
                </li>
                <li>
                  <div className={type === "HOTEL" ? "food-types px-2 py-2 active" : "food-types px-2 py-2"} onClick={() => setType("HOTEL")}>
                    <i className="ri-building-4-fill light-text"></i>
                    <h5 className="light-text">Hotel</h5>
                  </div>
                </li>
                <li>
                  <div className={type === "OTHER" ? "food-types px-2 py-2 active" : "food-types px-2 py-2"} onClick={() => setType("OTHER")}>
                    <i className="ri-map-pin-fill light-text"></i>
                    <h5 className="light-text">Other</h5>
                  </div>
                </li>
              </ul>
            </div>
          </section>

          <Form onSubmit={localStorage.getItem("AddressEdit") == "true" ?
            (e) => { handleUpdateAddress(e) }
            :
            (e) => { handleSetDefaultAddress(e) }}>
            <section className="section-b-space">
              <div className="custom-container">
                <div className="form-group">
                  <label className="form-label fw-semibold">Address</label>
                  <div className="form-input mb-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Address"
                      required
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label fw-semibold">
                    Nearby Landmark (optional)
                  </label>
                  <div className="form-input mb-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Landmark"
                      value={landmark}
                      onChange={(e) => setLandmark(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </section>
            <div className="position-fixed bottom-0 px-3 w-100 py-2 bg-white">
              <button type="submit" className="btn theme-btn save-address w-100 mt-0">
                Save Address
              </button>
            </div>
          </Form>
        </div>
      }
    </div>
  );
}

export default AddressDetails;
