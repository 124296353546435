import React from 'react'

function GroceryHome() {
  return (
    <div>
        

    {/* <!-- side bar start --> */}
    <div className="offcanvas sidebar-offcanvas offcanvas-start" tabIndex="-1" id="offcanvasLeft">
        <div className="offcanvas-header">
            <img className="img-fluid profile-pic" src="assets/images/icons/profile.png" alt="profile" />
            <h3>Hello, Lina John</h3>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <div className="sidebar-content">
                <ul className="link-section">
                    <li>
                        <div className="pages">
                            <h3>RTL</h3>
                            <div className="switch-btn">
                                <input id="dir-switch" type="checkbox" />
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="pages">
                            <h3>Dark</h3>
                            <div className="switch-btn">
                                <input id="dark-switch" type="checkbox" />
                            </div>
                        </div>
                    </li>

                    <li>
                        <a href="page-listing" className="pages">
                            <h3>Pages List</h3>
                            <i className="ri-arrow-drop-right-line"></i>
                        </a>
                    </li>

                    <li>
                        <a href="setting" className="pages">
                            <h3>Setting</h3>
                            <i className="ri-arrow-drop-right-line"></i>
                        </a>
                    </li>

                    <li>
                        <a href="#" className="pages">
                            <h3>Privacy Policy</h3>
                            <i className="ri-arrow-drop-right-line"></i>
                        </a>
                    </li>


                    <li>
                        <a href="login" className="pages">
                            <h3>Logout</h3>
                            <i className="ri-arrow-drop-right-line"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    {/* <!-- side bar end --> */}

    {/* <!-- header start --> */}
    <header className="section-t-space">
        <div className="custom-container">
            <div className="header">
                <div className="head-content">
                    <button className="sidebar-btn" type="button" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasLeft">
                        <i className="ri-menu-line"></i>
                    </button>
                    <div className="header-location">
                        <a href="#location" data-bs-toggle="modal">
                            <h2>Ontario, Canada</h2>
                            <i className="ri-arrow-down-s-line d-arrow"></i>
                        </a>
                    </div>
                </div>
                <a href="notification">
                    <i className="ri-notification-2-fill"></i>
                </a>
            </div>
        </div>
    </header>
    {/* <!-- header end --> */}

    {/* <!-- search section starts --> */}
    <section className="search-section">
        <div className="custom-container">
            <form className="auth-form search-head" target="_blank">
                <div className="form-group">
                    <div className="form-input">
                        <input type="search" className="form-control search" id="inputusername"
                            placeholder="Restaurant, item & more" />
                        <i className="ri-search-line search-icon"></i>
                    </div>

                    <a href="#search-filter" className="btn filter-button mt-0" data-bs-toggle="modal">
                        <i className="ri-equalizer-line"></i>
                    </a>
                </div>
            </form>
        </div>
    </section>
    {/* <!-- search section end --> */}

    {/* <!-- categories section start --> */}
    <section className="search-section">
        <div className="custom-container">
            <div className="title">
                <h3 className="mt-0">Food Categories</h3>
                <a href="categories">See All</a>
            </div>
            <div className="swiper grocery-categories">
                <div className="swiper-wrapper">
                    <div className="swiper-slide">
                        <div className="grocery-categories-box active">
                            <img src="assets/images/grocery/svg/1.svg" alt="1" />
                            <h6>Bread</h6>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="grocery-categories-box">
                            <img src="assets/images/grocery/svg/2.svg" alt="2" />
                            <h6>Shrimps</h6>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="grocery-categories-box">
                            <img src="assets/images/grocery/svg/3.svg" alt="3" />
                            <h6>Meat</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- categories section start --> */}

    {/* <!-- banner section starts --> */}
    <section className="search-section">
        <div className="custom-container">
            <img className="img-fluid banner-img" src="assets/images/grocery/banner12.png" alt="banner12" />
        </div>
    </section>
    {/* <!-- banner section end --> */}

    {/* <!-- offer product section starts --> */}
    <section className="search-section">
        <div className="custom-container">
            <div className="title mb-0">
                <h3 className="mt-0">Essential item</h3>
                <a href="details">See All</a>
            </div>
            <h6 className="sub-title light-text mb-3">Special offer on first purchase</h6>

            <div className="swiper grocery-product">
                <div className="swiper-wrapper">
                    <div className="swiper-slide">
                        <div className="grocery-product-box">
                            <h6 className="offer-tag">20% off</h6>

                            <div className="grocery-product-img">
                                <img className="img-fluid img" src="assets/images/grocery/p1.png" alt="p1" />
                            </div>
                            <div className="grocery-product-details">
                                <h4 className="dark-text">Strawberry Milkshake Powder</h4>
                                <h4 className="light-text price"><span className="theme-color">$16/kg </span>| 20min</h4>
                                <a href="cart" className="btn theme-btn add-btn w-100   mt-3">+ Add</a>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="grocery-product-box">
                            <h6 className="offer-tag">20% off</h6>
                            <div className="grocery-product-img">
                                <img className="img-fluid img" src="assets/images/grocery/p2.png" alt="p1" />
                            </div>
                            <div className="grocery-product-details">
                                <h4 className="dark-text">Fresh Cabbage</h4>
                                <h4 className="light-text price"><span className="theme-color">$16/kg </span>| 20min</h4>
                                <a href="cart" className="btn theme-btn add-btn w-100 mt-3">+ Add</a>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="grocery-product-box">
                            <h6 className="offer-tag">20% off</h6>
                            <div className="grocery-product-img">
                                <img className="img-fluid img" src="assets/images/grocery/p3.png" alt="p1" />
                            </div>
                            <div className="grocery-product-details">
                                <h4 className="dark-text">Orange Juice</h4>
                                <h4 className="light-text price"><span className="theme-color">$16/kg </span>| 20min</h4>
                                <a href="cart" className="btn theme-btn add-btn w-100 mt-3">+ Add</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- offer product section end --> */}

    {/* <!-- popular product section starts --> */}
    <section>
        <div className="custom-container">
            <div className="title mb-0">
                <h3 className="mt-0">Popular Product</h3>
                <a href="details">See All</a>
            </div>
            <h6 className="sub-title light-text mb-3">Fresh product from our mart</h6>

            <ul className="grocery-horizontal-product-box gap-0 mt-3">
                <li className="w-100">
                    <div className="horizontal-product-img">
                        <img className="img-fluid img" src="assets/images/grocery/p4.png" alt="p4" />
                    </div>
                    <div className="horizontal-product-details">
                        <div>
                            <h5 className="fw-semibold dark-text">Oatmeal cookies</h5>
                            <h4 className="light-text price mt-1 mb-1"><span className="theme-color">$16/kg </span>| 20min</h4>
                        </div>
                        <div className="d-flex align-items-center gap-1">
                            <img className="img-fluid" src="assets/images/svg/rating-star.svg" alt="star" />
                            <h6 className="rate-color">4.5</h6>
                        </div>

                        <a href="cart" className="btn theme-btn add-btn mt-0">+ Add</a>
                    </div>
                </li>

                <li className="w-100">
                    <div className="horizontal-product-img">
                        <img className="img-fluid img" src="assets/images/grocery/p5.png" alt="p5" />
                    </div>
                    <div className="horizontal-product-details">
                        <div>
                            <h5 className="fw-semibold dark-text">Organic Nuts</h5>
                            <h4 className="light-text price mt-1 mb-1"><span className="theme-color">$16/kg </span>| 20min
                            </h4>
                        </div>
                        <div className="d-flex align-items-center gap-1">
                            <img className="img-fluid" src="assets/images/svg/rating-star.svg" alt="star" />
                            <h6 className="rate-color">4.5</h6>
                        </div>
                        <a href="cart" className="btn theme-btn add-btn mt-0">+ Add</a>
                    </div>
                </li>
                <li className="w-100">
                    <div className="horizontal-product-img">
                        <img className="img-fluid img" src="assets/images/grocery/p6.png" alt="p6" />
                    </div>
                    <div className="horizontal-product-details">
                        <div>
                            <h5 className="fw-semibold dark-text">Telvita Breakfast Meal</h5>
                            <h4 className="light-text price mt-1 mb-1"><span className="theme-color">$16/kg </span>| 20min
                            </h4>
                        </div>
                        <div className="d-flex align-items-center gap-1">
                            <img className="img-fluid" src="assets/images/svg/rating-star.svg" alt="star" />
                            <h6 className="rate-color">4.5</h6>
                        </div>
                        <a href="cart" className="btn theme-btn add-btn mt-0">+ Add</a>
                    </div>
                </li>
            </ul>
        </div>
    </section>
    {/* <!-- popular product section end --> */}

    {/* <!-- sale banner section starts --> */}
    <section className="search-section">
        <div className="custom-container">
            <div className="title mb-0">
                <h3 className="mt-0">Sale of the week </h3>
            </div>
            <h6 className="sub-title light-text mb-3">Get great product on sale of this week</h6>
            <div className="row">
                <div className="col-6">
                    <img className="img-fluid sale-banner" src="assets/images/grocery/banner13.png" alt="banner13" />
                </div>
                <div className="col-6">
                    <img className="img-fluid sale-banner" src="assets/images/grocery/banner14.png" alt="banner14" />
                </div>
            </div>
        </div>
    </section>
    {/* <!-- sale banner section end --> */}

    {/* <!-- best selling section starts --> */}
    <section className="search-section">
        <div className="custom-container">
            <div className="title mb-0">
                <h3 className="mt-0">Best Selling</h3>
                <a href="details">See All</a>
            </div>
            <h6 className="sub-title light-text mb-3">Get your product from best seller</h6>
            <div className="row">
                <div className="col-6">
                    <div className="grocery-product-box">
                        <div className="like-icon animate active inactive">
                            <i className="ri-heart-3-line outline-icon"></i>
                            <i className="ri-heart-3-fill fill-icon"></i>
                            <div className="effect-group">
                                <span className="effect"></span>
                                <span className="effect"></span>
                                <span className="effect"></span>
                                <span className="effect"></span>
                                <span className="effect"></span>
                            </div>
                        </div>

                        <div className="grocery-product-img">
                            <img className="img-fluid img" src="assets/images/grocery/p1.png" alt="p1" />
                        </div>
                        <div className="grocery-product-details">
                            <h4>Strawberry Milkshake Powder</h4>
                            <h4 className="light-text price"><span className="theme-color">$16/kg </span>| 20min</h4>
                            <a href="cart" className="btn theme-btn add-btn w-100 mt-3">+ Add</a>

                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="grocery-product-box">
                        <div className="like-icon">
                            <i className="ri-heart-3-line outline-icon"></i>
                            <i className="ri-heart-3-fill fill-icon"></i>
                            <div className="effect-group">
                                <span className="effect"></span>
                                <span className="effect"></span>
                                <span className="effect"></span>
                                <span className="effect"></span>
                                <span className="effect"></span>
                            </div>
                        </div>
                        <div className="grocery-product-img">
                            <img className="img-fluid img" src="assets/images/grocery/p3.png" alt="p1" />
                        </div>
                        <div className="grocery-product-details">
                            <h4 className="dark-text">Orange Juice</h4>
                            <h4 className="light-text price"><span className="theme-color">$16/kg </span>| 20min</h4>
                            <a href="cart" className="btn theme-btn add-btn w-100 mt-3">+ Add</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- best selling section end --> */}

    {/* <!-- Recent Items section starts --> */}
    <section className="section-b-space">
        <div className="custom-container">
            <div className="title mb-0">
                <h3 className="mt-0">Recent Items</h3>
                <a href="details">See All</a>
            </div>
            <h6 className="sub-title light-text mb-3">Fresh product from our mart</h6>

            <div className="row g-3">
                <div className="col-12">
                    <div className="grocery-horizontal-product-box2 ">
                        <div className="horizontal-product-img">
                            <img className="img-fluid img" src="assets/images/grocery/p4.png" alt="p4" />
                        </div>
                        <div className="horizontal-product-details">
                            <div className="d-flex align-items-center justify-content-between">
                                <h5 className="fw-semibold dark-text">Oatmeal cookies</h5>
                                <div className="d-flex align-items-center gap-1">
                                    <img className="img-fluid" src="assets/images/svg/rating-star.svg" alt="star" />
                                    <h6 className="rate-color">4.5</h6>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-2">
                                <h6 className="light-text price mt-1 mb-1">20 min away home</h6>
                                <h5 className="theme-color"> <span className="fw-semibold">$16</span>/kg</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="grocery-horizontal-product-box2">
                        <div className="horizontal-product-img">
                            <img className="img-fluid img" src="assets/images/grocery/p5.png" alt="p5" />
                        </div>
                        <div className="horizontal-product-details">
                            <div className="d-flex align-items-center justify-content-between">
                                <h5 className="fw-semibold dark-text">Organic Nuts</h5>
                                <div className="d-flex align-items-center gap-1">
                                    <img className="img-fluid" src="assets/images/svg/rating-star.svg" alt="star" />
                                    <h6 className="rate-color">4.5</h6>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-2">
                                <h6 className="light-text price mt-1 mb-1">20 min away home</h6>
                                <h5 className="theme-color"> <span className="fw-semibold">$16</span>/kg</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="grocery-horizontal-product-box2">
                        <div className="horizontal-product-img">
                            <img className="img-fluid img" src="assets/images/grocery/p3.png" alt="p3" />
                        </div>
                        <div className="horizontal-product-details">
                            <div className="d-flex align-items-center justify-content-between">
                                <h5 className="fw-semibold dark-text">Orange Juice</h5>
                                <div className="d-flex align-items-center gap-1">
                                    <img className="img-fluid" src="assets/images/svg/rating-star.svg" alt="star" />
                                    <h6 className="rate-color">4.5</h6>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-2">
                                <h6 className="light-text price mt-1 mb-1">20 min away home</h6>
                                <h5 className="theme-color"> <span className="fw-semibold">$16</span>/kg</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="grocery-horizontal-product-box2">
                        <div className="horizontal-product-img">
                            <img className="img-fluid img" src="assets/images/grocery/p7.png" alt="p7" />
                        </div>
                        <div className="horizontal-product-details">
                            <div className="d-flex align-items-center justify-content-between">
                                <h5 className="fw-semibold dark-text">Butter Biscuit
                                </h5>
                                <div className="d-flex align-items-center gap-1">
                                    <img className="img-fluid" src="assets/images/svg/rating-star.svg" alt="star" />
                                    <h6 className="rate-color">4.5</h6>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-2">
                                <h6 className="light-text price mt-1 mb-1">20 min away home</h6>
                                <h5 className="theme-color"> <span className="fw-semibold">$16</span>/kg</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </section>
    {/* <!-- Recent Items section end --> */}

    {/* <!-- panel-space start --> */}
    <section className="panel-space"></section>
    {/* <!-- panel-space end --> */}

    {/* <!-- bottom navbar start -->/ */}
    <div className="navbar-menu">
        <ul>
            {/* <li>
                <a href="home" className="icon logo">
                    <img className=" img-fluid" src="assets/images/svg/grocery-logo.svg" alt="logo" />
                    <span>Zomo</span>
                </a>
            </li> */}
            <li>
                <a href="home" className="icon">
                    <img className="unactive" src="assets/images/svg/food.svg" alt="food" />
                    <img className="active" src="assets/images/svg/food-fill.svg" alt="food" />
                    <span>Food</span>
                </a>
            </li>
            <li className="active">
                <a href="grocery-home" className="icon">

                    <img className="unactive" src="assets/images/svg/grocery.svg" alt="grocery" />
                    <img className="active" src="assets/images/svg/grocery-fill.svg" alt="grocery" />
                    <span>Grocery</span>
                </a>
            </li>
            <li>
            <a href="cart" class="icon">
              <img
                class="unactive"
                src="assets/images/svg/cart.svg"
                alt="user"
              />
              <img
                class="active"
                src="assets/images/svg/cart-fill.svg"
                alt="user"
              />
              <span>Cart</span>
            </a>
          </li>
            {/* <li>
                <a href="pharmacy-home" className="icon">

                    <img className="unactive" src="assets/images/svg/pharmacy.svg" alt="pharmacy" />
                    <img className="active" src="assets/images/svg/pharmacy-fill.svg" alt="pharmacy" />
                    <span>Pharmacy</span>
                </a>
            </li> */}
            <li>
                <a href="profile" className="icon">

                    <img className="unactive" src="assets/images/svg/user.svg" alt="user" />
                    <img className="active" src="assets/images/svg/user-fill.svg" alt="user" />
                    <span className="active">Profile</span>
                </a>
            </li>
        </ul>
    </div>
    {/* <!-- bottom navbar end --> */}

 
</div>
  )
}

export default GroceryHome