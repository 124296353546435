import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import ProgressiveImage from 'react-progressive-image';
import { IMAGE_BASE_URL } from '../../../config';
import no_image from '../../../assets/images/rp1.png'
function Banner({ banners }) {

    return (
        <section className="banner-section section-t-space">
            <Swiper>
                {banners && banners.length > 0 && banners.map((banner, index) => (
                    <SwiperSlide key={index}>
                        <div style={{ padding: '0px 12px' }}>
                            <ProgressiveImage
                                delay={20}
                                src={IMAGE_BASE_URL + banner.image_path}
                                placeholder={no_image}
                            >
                                {(src) => (
                                    <img
                                        src={src}
                                        style={{
                                            borderRadius: '15px',
                                            height: '12rem',
                                            objectFit:'cover'
                                        }}
                                        className="img-fluid banner-img"
                                        alt="deleevro"
                                    />
                                )}
                            </ProgressiveImage>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>
    );
}

export default Banner