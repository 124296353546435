import React, { useEffect, useState } from 'react'
import CartItems from './CartItems';
import { useDispatch, useSelector } from 'react-redux';
import Lottie from 'react-lottie';
import empty_cart from '../../assets/others/empty_cart.json'
import loadingLottie from '../../assets/others/track-order-loading.json'
import BillDetails from './BillDetails';
import DeliveryAddress from './DeliveryAddress';
import ApplyCoupon from './ApplyCoupon';
import Tip from './Tip';
import { Drawer } from "@material-ui/core";
import { RiCloseCircleLine } from 'react-icons/ri';
import moment from 'moment';
import { calcualteShopMaxDistance, calculateShopChargeTax, clearCart, placeOrder } from '../../Redux/Store/cartReducer';
import { Swiper, SwiperSlide } from 'swiper/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import 'swiper/css';
import { IoIosRadioButtonOff, IoIosRadioButtonOn } from 'react-icons/io';
import Footer from '../Home/Footer';
import { getUserProfile, logoutUser } from '../../Redux/Store/userReducer';

function Cart() {
  const dispatch = useDispatch();
  const cartProducts = useSelector((state) => state.cart);
  const [tip, setTip] = useState('');
  const [deliveryType, setDeliveryType] = useState(true);
  const [deliverySheet, setDeliverySheet] = useState(false);
  const [scheduledTime, setScheduledTime] = useState(localStorage.getItem('schedule_time') ?? null);
  const [scheduledDate, setScheduledDate] = useState(localStorage.getItem('schedule_date') ?? null);
  const [isScheduled, setIsScheduled] = useState(false);
  const [paymentType, setPaymentType] = useState(null);
  const [notselected, setNotselected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);

  //updated starts
  let schedule_date = localStorage.getItem('is_schedule') == 'true' ? localStorage.getItem("schedule_date") : null;
  let schedule_time = localStorage.getItem('is_schedule') == 'true' ? localStorage.getItem("schedule_time") : null;
  let schedule_start_time = localStorage.getItem('is_schedule') == 'true' ? localStorage.getItem("schedule_start_time") : null;
  const [selectedDate, setSelectedDate] = useState(schedule_date ? schedule_date : moment().format('MMM D, YYYY'))
  const [selectedTime, setSelectedTime] = useState(schedule_time)
  const [stateChange, setStateChange] = useState(false)
  const [startTime, setStartTime] = useState(schedule_start_time)
  //updated ends

  useEffect(() => {
    window.scrollTo(0, 0);
    if (localStorage.getItem("tip") > 0) {
      setTip(localStorage.getItem("tip"))
    } else {
      setTip(0)
    }
    if (localStorage.getItem("scheduledDate") && localStorage.getItem("scheduledTime")) {
      setDeliveryType(false)
      setIsScheduled(true)
      setScheduledDate(localStorage.getItem("scheduledDate"))
      setScheduledTime(localStorage.getItem("scheduledTime"))
    }
  }, []);

  const addTip = (e) => {
    if (e == tip) {
      setTip(0)
      localStorage.setItem("tip", 0)
    } else {
      setTip(e)
      localStorage.setItem("tip", e)
    }
  };

  const calculatetip = () => {
    if (tip) {
      return tip;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    const formData = new FormData();
    formData.append("shop_id", cartProducts.shop_id);
    formData.append("latitude", localStorage.getItem("userLat"));
    formData.append("longitude", localStorage.getItem("userLng"));
    // dispatch(getShopDistance(formData)) 
    const formData2 = new FormData();
    const cartItems = JSON.stringify(cartProducts.orderitems)
    formData2.append("id", localStorage.getItem('user_id'));
    formData2.append("shop_id", cartProducts.shop_id);
    formData2.append("products", cartItems);
    formData2.append("latitude", localStorage.getItem("userLat"));
    formData2.append("longitude", localStorage.getItem("userLng"));
    dispatch(calcualteShopMaxDistance(formData2));
    const formData3 = new FormData();
    formData3.append("products", cartItems);
    dispatch(calculateShopChargeTax(formData3))
    const formData4 = new FormData();
    formData4.append("user_id", localStorage.getItem('user_id'));
    formData4.append("token", localStorage.getItem('device_token'));
    dispatch(getUserProfile(formData4)).then((response) => {
      if (response && response.payload && response.payload.success) {
        setTokenExpired(false)
      } else {
        setTokenExpired(true)
      }
    });
  }, []);
  const totalPrice = () => {
    let discount = 0;
    if (cartProducts?.appliedCoupon) {
      if (cartProducts?.appliedCoupon?.discount_type === "amount") {
        discount += parseFloat(cartProducts?.appliedCoupon?.used_amount);
      } else {
        discount += parseFloat(cartProducts?.totalAmount * parseFloat(cartProducts?.appliedCoupon?.used_amount) / 100);
        let max_discount = parseFloat(cartProducts?.appliedCoupon?.upto);
        if (max_discount > 0 && max_discount <= discount) {
            discount = parseFloat(cartProducts?.appliedCoupon?.upto);
        }
      }
    }
    let tax = 0;
    let surge_charge = 0;
    if (cartProducts?.shop_tax?.success) {
      tax = cartProducts?.shop_tax?.tax > 0 ? ((parseFloat(cartProducts?.totalAmount) * parseFloat(cartProducts?.shop_tax?.tax)) / 100) : 0;
      surge_charge = cartProducts?.shop_tax?.surge_charge;
    }

    let packing_charge = 0;
    if (cartProducts?.shop_distance?.shop_id?.packing_charge && cartProducts?.shop_distance?.shop_id?.packing_charge > 0) {
      packing_charge = cartProducts?.shop_distance?.shop_id?.packing_charge;
    }

    let delivery_charge = 0;
    if (cartProducts?.shop_distance?.shop_id?.free_delivery && cartProducts?.shop_distance?.shop_id?.free_delivery_subtotal <= cartProducts.totalAmount) {
      delivery_charge = 0;
    } else {
      delivery_charge = parseFloat(cartProducts.deliveryCharge);
    }

    if (discount > 0) {
      return (parseFloat(cartProducts.totalAmount + parseFloat(tip)) - parseFloat(discount) + parseFloat(tax) + parseFloat(surge_charge) + parseFloat(delivery_charge) + parseFloat(packing_charge)).toFixed(2)
    } else {
      return (parseFloat(cartProducts.totalAmount + parseFloat(tip)) + parseFloat(tax) + parseFloat(surge_charge) + parseFloat(delivery_charge) + parseFloat(packing_charge)).toFixed(2)
    }
  }

  const _completeOrder = (e) => {
    if (paymentType == null) {
      setNotselected(true)
    } else {
      setLoading(true)
      e.preventDefault();
      const formData = new FormData();
      let cartProduct = JSON.stringify(cartProducts.orderitems);
      let defaultAddress = localStorage.getItem("userSetAddress");
      formData.append("user_id", localStorage.getItem('user_id'));
      formData.append("token", localStorage.getItem('device_token'));
      formData.append("paymentMode", paymentType);
      formData.append("code", cartProducts?.appliedCoupon?.coupon_code ?? null);
      formData.append("cartProducts", cartProduct);
      formData.append("subtotal", cartProducts.totalAmount);
      let discount = 0;
      if (cartProducts?.appliedCoupon?.discount_type === "FIXED") {
        discount += parseFloat(cartProducts?.discount);
      } else {

        discount += parseFloat(cartProducts?.totalAmount * cartProducts?.discount / 100);
        let max_discount = parseFloat(cartProducts?.appliedCoupon?.max_discount);
        if (max_discount > 0 && max_discount <= discount) {
          discount = parseFloat(cartProducts?.appliedCoupon?.max_discount);
        }
      }
      formData.append("discount", discount);
      formData.append("distance", cartProducts?.store_distance?.distance ?? 0);
      formData.append("address", defaultAddress);
      formData.append("store_id", cartProducts?.shop_id);
      formData.append("is_schedule", isScheduled);
      formData.append("schedule_date", scheduledDate);
      formData.append("schedule_time", scheduledTime);
      formData.append("tip", tip);
      let totalPrices = totalPrice();
      formData.append("totalPayableAmount", totalPrices);
      dispatch(placeOrder(formData)).then((response) => {
        if (response && response.payload && response.payload.success) {
          dispatch(clearCart());
          window.location.replace("/order-success/" + response.payload.data.id)
        } else {
          setLoading(false)
          console.log('error')
        }
      })
    }
  }

  const checkToken = () => {
    setLoading(true)
    if (tokenExpired) {
      localStorage.removeItem("user_id")
      localStorage.removeItem("userLogin")
      localStorage.removeItem("auth_token")
      localStorage.removeItem("is_location")
      localStorage.removeItem("address")
      localStorage.removeItem("geoLocation")
      localStorage.removeItem("userSetAddress")
      localStorage.removeItem("userLng")
      localStorage.removeItem("userLat")
      dispatch(logoutUser())
      window.location.replace("/login")
    } else {
      window.location.href = "/managePayment";
    }

  }

  const itemCount = () => {
    let count = 0
    if (cartProducts?.orderitems?.length > 0) {
      cartProducts?.orderitems?.map((orderItem) => {
        if (orderItem?.selectedaddons?.length > 0) {
          count += orderItem.selectedaddons.length;
        } else {
          count += 1;
        }
      })
      return parseInt(count);
    } else {
      return count;
    }
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: empty_cart,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const loadingLott = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const today = new Date();
  const next10Days = Array.from({ length: 5 }, (_, index) => {
    const date = new Date();
    date.setDate(today.getDate() + index);
    return {
      week: date.toLocaleDateString('en-US', { weekday: 'short', }),
      month: date.toLocaleDateString('en-US', { month: 'short' }),
      day: date.toLocaleDateString('en-US', { day: 'numeric' }),
      date: date.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' }),
      isToday: index === 0, // Check if it's today
    };
  });

  const generateTimeRanges = (selectedDate) => {
    const now = new Date(selectedDate);
    const currentTime = new Date();
    if (now < currentTime) {
      now.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds());
    }

    const formatTime = (time) => {
      return time.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' }).replace(/^0/, '').replace(/\s/g, ''); // Remove spaces
    };

    const formatTimes = (time) => {
      return time.toLocaleTimeString('en-US');
    };

    const timeRanges = [];
    for (let i = 9; i <= 19; i++) {
      const startTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), i, 0, 0);
      const endTime = new Date(startTime.getTime() + 60 * 60 * 1000);
      const startingTime = formatTimes(startTime);
      const timeRange = `${formatTime(startTime)} - ${formatTime(endTime)}`;
      const isTimeRangeEnded = now.getTime() + 60 * 60 * 1000 >= endTime.getTime();
      timeRanges.push({ timeRange, isTimeRangeEnded, startingTime });
    }

    return timeRanges;
  };

  const change = () => {
    if (stateChange) {
      setSelectedTime(null)
      setStateChange(false)
    }
  }

  const handleCategory = () => {
  }

  return (
    <div>
      {loading ?
        <div className="flex items-center justify-center" style={{ paddingTop: '15rem' }}>
          <Lottie
            options={loadingLott}
            height={100}
            width={100}
          />
        </div>
        : <>
          <header class="section-t-space">
            <div class="custom-container">
              <div className="d-flex align-items-center">
                <div onClick={() => { window.history.back() }}>
                  <i className="ri-arrow-left-s-line fs-5"></i>
                </div>
                <h2 className="flex-grow-1 text-center" style={{ fontWeight: '600' }}>Cart</h2>
              </div>
            </div>
          </header>
          {cartProducts?.orderitems?.length > 0 ?
            <>
              <CartItems />
              <ApplyCoupon />
              <Tip addTip={(e) => addTip(e)}
                tip={tip} />
              <DeliveryAddress />
              <section class="delivery address">
                <h3 class="fw-semibold dark-text px-3">Delivery Type </h3>
                <div className='mt-3'>
                  <div className="  d-flex flex-row align-items-center justify-content-between px-4 mt-4"  >
                    <div className={deliveryType ? "instant-type" : "schedule-type "} onClick={() => {
                      setDeliveryType(true)
                      setIsScheduled(false)
                      localStorage.removeItem("scheduledDate")
                      localStorage.removeItem("scheduledTime")
                    }}>
                      Instant
                    </div>
                    <div className={deliveryType ? "schedule-type" : "instant-type"} onClick={() => {
                      setDeliveryType(false)
                      setDeliverySheet(true)
                      setIsScheduled(true)
                    }}>
                      Scheduled
                    </div>
                  </div>
                </div>

                {!deliveryType && schedule_date && schedule_time ?
                  <div className=' text-center fw-bolder mt-2 px-4 p-2'>
                    <div className='schedule-type w-100'>
                      <div>Scheduled Date & Time</div>
                      <div> Schedule date <span>{schedule_date}</span></div>
                      <div> Schedule Time <span>{schedule_time}</span></div>

                    </div>
                  </div> : null}
              </section>
              <BillDetails tip={calculatetip()} />

              <div class="pay-popup">
                <div class="price-items">
                  <h3>₹ {totalPrice()}</h3>
                  <h6>{itemCount()} item Added</h6>
                </div>
                <div class="btn theme-btn pay-btn mt-0" onClick={() => checkToken()}>Pay Now</div>
              </div>
            </>
            :
            <div className="flex items-center justify-center" style={{ paddingTop: '5rem' }}>
              <Lottie
                options={defaultOptions}
                height={300}
                width={300}
              />
              <div className='text-center pt-5 fs-2' style={{ fontWeight: '600' }}>
                Your Cart is Empty
              </div>
              <div className='text-center pt-1 fs-6 text-muted' style={{ fontWeight: '600' }}>
                Add Items To Cart, Keep Shopping
              </div>
            </div>

          }
          <Drawer
            anchor="bottom"
            open={deliverySheet}
            onClose={() => setDeliverySheet(false)}
            swipeAreaWidth={15} >
            <div style={{ borderRadius: '15px 15px 0px 0px' }}>
              <div className='p-3' style={{ borderRadius: '15px 15px 0px 0px' }}>
                <div className='sticky top-20vh'>
                  <div className='d-flex justify-content-end align-items-end'>
                    <div onClick={() => { setDeliverySheet(false) }}>
                      <RiCloseCircleLine size={25} />
                    </div>
                  </div>
                  <div className='flex flex-start text-lg px-2 pt-3 pb-2 mt-2' style={{ fontWeight: "600" }} >  Choose Schedule Date & Time</div>
                  <div className='ps-3'>
                    <Swiper slidesPerView={3} spaceBetween={0}  >
                      {next10Days.map((day, index) => (
                        <SwiperSlide key={index} style={{ width: '100px' }}>
                          <div className='schedulecards flex flex-col items-center px-2' onClick={() => { setSelectedDate(day.date); setStateChange(true) }} style={selectedDate == day.date ? { backgroundColor: " #7ac356", color: 'white' } : { backgroundColor: '#f8f9fa', color: '#000' }}>
                            <div className='font-bold'>{day.isToday ? "TODAY" : day.week.toUpperCase()}</div>
                            <div>
                              {day.day} {day.month}
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>

                <div>
                  <div className='flex flex-col justify-center items-center justify-between mt-3' style={{ paddingBottom: '6rem' }}>
                    <Box sx={{ width: '100%' }} >
                      <Stack spacing={2} justifyContent="space-between" className='px-3'>
                        {generateTimeRanges(selectedDate).map((time, index) => (
                          <Typography sx={{ width: '100%', borderRadius: '10px', background: ' #F8F9FA', border: "1px solid #7ac356" }} key={index} >
                            <div className="d-flex flex-row  items-center px-2 py-2" style={{ justifyContent: "space-between" }} onClick={() => {
                              if (!time.isTimeRangeEnded) {
                                setSelectedTime(time.timeRange);
                                setStartTime(time.startingTime);
                              }
                            }}>
                              {change()}
                              <div>
                                {time.isTimeRangeEnded ?
                                  <div>
                                    <IoIosRadioButtonOff size={24} color='#c7c7c7' />
                                  </div>
                                  :
                                  <div>
                                    {selectedTime === time.timeRange ?
                                      <IoIosRadioButtonOn size={24} color='#7ac356' />
                                      :
                                      <IoIosRadioButtonOff size={24} />
                                    }
                                  </div>
                                }
                              </div>
                              <div className='grow text-start px-3' style={time.isTimeRangeEnded ? { color: '#c7c7c7' } : {}} > {time.timeRange.toLowerCase()} </div>
                              <div>
                                {time.isTimeRangeEnded ?
                                  <div className='text-red-500'>
                                    Not available
                                  </div>
                                  : null}
                              </div>

                            </div>
                          </Typography>
                        ))}
                      </Stack>
                    </Box>

                  </div>
                </div>
              </div>
              <div className="position-fixed bottom-0 py-2 px-3 pb-6 mt-2  bg-color-white w-100 bg-white" style={{ width: "100%" }}>
                <button className="send-otp-button " onClick={() => {
                  localStorage.setItem('is_schedule', true);
                  localStorage.setItem("schedule_date", selectedDate);
                  localStorage.setItem("schedule_time", selectedTime);
                  localStorage.setItem("schedule_start_time", startTime);
                  // window.location.replace('/checkout')
                  setDeliverySheet(false)
                }}>Set Schedule</button>
              </div>
            </div>
          </Drawer>
        </>
      }
      {cartProducts.orderitems?.length > 0 ? null :
        <Footer category={'CART'} changeCategory={handleCategory} />
      }
    </div>
  )
}

export default Cart