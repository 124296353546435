import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import SplashScreen from './screens/SplashScreen';
import Login from './screens/Login';
import Location from './screens/Location/index.js';
import SetLocation from './screens/Location/SetLocation/index.js';
import Home from './screens/Home';
import Test from './screens/Test';
import Singlecategory from './screens/Singlecategory';
import Itemdetail from './screens/Itemdetail';
import Cart from './screens/Cart';
import Notification from './screens/Notification.js';
import Profile from './screens/Profile';
import ProfileSetting from './screens/Profile/ProfileSetting.js';
import ManageDeliveryAddress from './screens/Profile/ManageDeliveryAddress/index.js';
import WishList from './screens/Profile/WishList/index.js';
import ManagePayment from './screens/Profile/ManagePayment/index.js';
import OrderHistory from './screens/Profile/OrderHistory/index.js';
import Voucher from './screens/Profile/Voucher/index.js';
import OtherSetting from './screens/Profile/OtherSetting/index.js';
import NotificationSetting from './screens/Profile/NotificationSetting/index.js';
import Landing from './screens/Landing/index.js';
import GroceryHome from './screens/GroceryHome/index.js';
import Pharmacy from './screens/Pharmacy/index.js';
import Brandlist from './screens/Home/Brand/Brandlist.js/index.js';
import Categorylist from './screens/Home/Categories/Categorylist/index.js';
import Productlist from './screens/Home/Product/Productlist/index.js';
import Order from './screens/Order/index.js';
import AddressDetails from './screens/AddressDetails/index.js';
import ListCoupons from './screens/Cart/ApplyCoupon/ListCoupons/index.js';
import OrderSuccess from './screens/Cart/OrderSuccess/index.js';
import SingleStore from './screens/SingleStore/index.js';
import Ordertracking from './screens/Order/Ordertracking/index.js';
import SingleProduct from './screens/SingleProduct/index.js';
import CategoryItems from './screens/SingleStore/ItemCategories/CategoryItems/index.js';
import Search from './screens/Home/Search/index.js';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<SplashScreen />} />
        <Route path='/login' element={<Login />} />
        <Route path='/location' element={<Location />} />
        <Route path='/set-location' element={<SetLocation />} />
        <Route path='/home' element={<Home />} />
        <Route path='/test' element={<Test />} />
        <Route path='/single-category/:type/:id' element={<Singlecategory />} />
        <Route path='/single-store/:id' element={<SingleStore />} />
        <Route path='/itemdetail' element={<Itemdetail />} />
        <Route path='/cart' element={<Cart />} />
        <Route exact path="/list-coupons" element={<ListCoupons />} />
        <Route path='/notification' element={<Notification />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/search' element={<Search />} />
        <Route path='/profile-setting' element={<ProfileSetting />} />
        <Route path='/manage-delivery-address' element={<ManageDeliveryAddress />} />
        <Route path='/wishList' element={<WishList />} />
        <Route path='/managePayment' element={<ManagePayment />} />
        <Route path='/order-history' element={<OrderHistory />} />
        <Route path='/voucher' element={<Voucher />} />
        <Route path='/otherSetting' element={<OtherSetting />} />
        <Route path='/notificationSetting' element={<NotificationSetting />} />
        <Route path='/landing' element={<Landing />} />
        <Route path='/grocery-home' element={<GroceryHome />} />
        <Route path='/pharmacy' element={<Pharmacy />} />
        {/* see all list */}
        <Route exact path="/order-success/:id" element={<OrderSuccess />} />
        <Route path='/brand-list' element={<Brandlist />} />
        <Route path='/category-list' element={<Categorylist />} />
        <Route path='/productlist' element={<Productlist />} />
        <Route path='/order-tracking/:id' element={<Ordertracking />} />

        <Route path='/single-product/:id' element={<SingleProduct />} />
        <Route path='/category-products/:id' element={<CategoryItems />} />
        {/* order */}

        <Route path='/order' element={<Order />} />


        <Route path='/add-address' element={<AddressDetails />} />
      </Routes>
    </Router>
  );
}

export default App;
