import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, removeFromCart } from '../../../Redux/Store/cartReducer';

function BillDetails({ tip }) {
    const dispatch = useDispatch();
    const cartProducts = useSelector((state) => state.cart);
    const couponDiscount = () => {
        let discount = 0;
        if (cartProducts?.appliedCoupon) {
            if (cartProducts?.appliedCoupon?.discount_type === "amount") {
                discount += parseFloat(cartProducts?.appliedCoupon?.used_amount);
            } else {
                discount += parseFloat(cartProducts?.totalAmount * parseFloat(cartProducts?.appliedCoupon?.used_amount) / 100);
                let max_discount = parseFloat(cartProducts?.appliedCoupon?.upto);
                if (max_discount > 0 && max_discount <= discount) {
                    discount = parseFloat(cartProducts?.appliedCoupon?.upto);
                }
            }
        }
        return "-₹" + discount.toFixed(2)
    }

    const totalPrice = () => {
        let discount = 0;
        if (cartProducts?.appliedCoupon?.discount_type === "amount") {
            discount += parseFloat(cartProducts?.appliedCoupon?.used_amount);
        } else {
            discount += parseFloat(cartProducts?.totalAmount * parseFloat(cartProducts?.appliedCoupon?.used_amount) / 100);
            let max_discount = parseFloat(cartProducts?.appliedCoupon?.upto);
            if (max_discount > 0 && max_discount <= discount) {
                discount = parseFloat(cartProducts?.appliedCoupon?.upto);
            }
        }
        let tax = 0;
        let surge_charge = 0;
        if (cartProducts?.shop_tax?.success) {
            tax = cartProducts?.shop_tax?.tax > 0 ? ((parseFloat(cartProducts?.totalAmount) * parseFloat(cartProducts?.shop_tax?.tax)) / 100) : 0;
            surge_charge = cartProducts?.shop_tax?.surge_charge;
        }

        let packing_charge = 0;
        if (cartProducts?.shop_distance?.shop_id?.packing_charge && cartProducts?.shop_distance?.shop_id?.packing_charge > 0) {
            packing_charge = cartProducts?.shop_distance?.shop_id?.packing_charge;
        }

        let delivery_charge = 0;
        if (cartProducts?.shop_distance?.shop_id?.free_delivery && cartProducts?.shop_distance?.shop_id?.free_delivery_subtotal <= cartProducts.totalAmount) {
            delivery_charge = 0;
        } else {
            delivery_charge = parseFloat(cartProducts.deliveryCharge);
        }

        if (discount > 0) {
            return (parseFloat(cartProducts.totalAmount + parseFloat(tip)) - parseFloat(discount) + parseFloat(tax) + parseFloat(surge_charge) + parseFloat(delivery_charge) + parseFloat(packing_charge)).toFixed(2)
        } else {
            return (parseFloat(cartProducts.totalAmount + parseFloat(tip)) + parseFloat(tax) + parseFloat(surge_charge) + parseFloat(delivery_charge) + parseFloat(packing_charge)).toFixed(2)
        }
    }

    return (
        <div>
            <section class="bill-details section-lg-b-space" style={{ paddingBottom: '8rem' }}>
                <div class="custom-container">
                    <h3 class="fw-semibold dark-text">Bill Details</h3>
                    <div class="total-detail mt-3">
                        <div class="sub-total">
                            <h5>Sub Total</h5>
                            <h5 class="fw-semibold">₹{parseFloat(cartProducts.totalAmount).toFixed(2)}</h5>
                        </div>
                        {parseFloat(cartProducts?.shop_distance?.shop_id?.packing_charge) > 0 &&
                            <div class="sub-total mt-3">
                                <h5>Packing Charge</h5>
                                <h5 class="fw-semibold">₹{parseFloat(cartProducts?.shop_distance?.shop_id?.packing_charge).toFixed(2)}</h5>
                            </div>
                        }
                        <div class="sub-total mt-3">
                            <h5>Delivery Charge</h5>
                            <h5 class="fw-semibold">
                                {cartProducts?.shop_distance?.shop_id?.free_delivery && cartProducts?.shop_distance?.shop_id?.free_delivery_subtotal <= cartProducts.totalAmount ? (
                                    <h5 style={{ color: '#7ac356' }} >Free</h5>
                                ) : (
                                    <>
                                        {cartProducts && cartProducts.deliveryCharge && cartProducts.deliveryCharge > 0 ?
                                            <h5>₹{parseFloat(cartProducts.deliveryCharge).toFixed(2)}</h5>
                                            :
                                            <h5 style={{ color: '#7ac356' }} >Free</h5>
                                        }
                                    </>
                                )}
                            </h5>
                        </div>
                        {/* <h6 class="delivery-info light-text mt-2">Save ₹5 on Delivery fee by ordering above ₹30</h6> */}
                        {cartProducts.discount > 0 &&
                            <div class="sub-total mt-3">
                                <h5>Discount</h5>
                                <h5 class="fw-semibold" style={{ color: '#7ac356' }}>{couponDiscount()}</h5>
                            </div>
                        }
                        {localStorage.getItem('tip') > 0 &&
                            <div class="sub-total mt-3">
                                <h5>Tip</h5>
                                <h5 class="fw-semibold">₹{parseFloat(localStorage.getItem('tip')).toFixed(2)}</h5>
                            </div>
                        }
                        {parseFloat(cartProducts?.shop_tax?.surge_charge) > 0 &&
                            <div class="sub-total mt-3">
                                <h5>Surge Charge</h5>
                                <h5 class="fw-semibold">₹{parseFloat(cartProducts?.shop_tax?.surge_charge).toFixed(2)}</h5>
                            </div>
                        }
                        {parseFloat(cartProducts?.shop_tax?.tax) > 0 &&
                            <div class="sub-total mt-3">
                                <h5>Tax ({parseFloat(cartProducts?.shop_tax?.tax)}%)</h5>
                                <h5 class="fw-semibold" >₹{((parseFloat(cartProducts?.totalAmount) * parseFloat(cartProducts?.shop_tax?.tax)) / 100).toFixed(2)}</h5>
                            </div>
                        }

                        <div class="grand-total mt-3">
                            <h5 class="fw-semibold">Grand Total</h5>
                            <h5 class="fw-semibold amount">{totalPrice()}</h5>
                        </div>
                        <img class="dots-design" src="assets/images/svg/dots-design.svg" alt="dots" />
                    </div>
                </div>
            </section>
        </div>
    )
}

export default BillDetails