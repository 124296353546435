import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllOrders } from "../../../Redux/Store/orderReducer";
import ProgressiveImage from 'react-progressive-image';
import no_orders from '../../../assets/others/noorders.json'
import animationData from '../../../assets/others/track-order-loading.json'
import Lottie from "react-lottie";
import { IMAGE_BASE_URL } from "../../../config";
import no_image from '../../../assets/images/rp1.png'

function OrderHistory() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true)

  const orders = useSelector((state) => state.order?.order_data?.orders);

  useEffect(() => {
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem('user_id'));
    formData.append("token", localStorage.getItem('device_token'));
    dispatch(getAllOrders(formData)).then((response) => {
      if (response && response.payload && response.payload.success) {
        setLoading(false)
      } else {
        setLoading(false)
      }
    })

  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const noOrders = {
    loop: true,
    autoplay: true,
    animationData: no_orders,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div>
      {loading ?
        <div className='' style={{ paddingTop: '15rem' }}>
          <Lottie
            options={defaultOptions}
            height={100}
            width={100}
          />
        </div> :
        <div style={{ paddingBottom: '3rem' }}>

          <header className="section-t-space">
            <div className="custom-container">
              <div className="header-panel">
                <Link to={'/profile'}>
                  <i className="ri-arrow-left-s-line"></i>
                </Link>
                <h2>Order History</h2>
              </div>
            </div>
          </header>

          {orders && orders.length > 0 ?
            <section className="section-t-space">
              <div className="custom-container">
                <div className="row gy-3">
                  {orders && orders.map((order) => (
                    <div className="col-12">
                      <div className="vertical-product-box order-box align-items-center">
                        <div className="vertical-box-img p-1">
                          <ProgressiveImage
                            delay={20}
                            src={IMAGE_BASE_URL + order?.shop?.image}
                            placeholder={no_image}
                          >
                            {(src) => (
                              <img
                                src={src}
                                style={{
                                  width: "100%",
                                  borderRadius: "15px",
                                  objectFit: "cover",
                                  backgroundRepeat: "round",
                                }}
                                className=""
                                alt="zeato"
                              />
                            )}
                          </ProgressiveImage>
                          {order?.orderstatus_id !== 7 && order?.orderstatus_id !== 8 ?
                            <div className="d-flex mt-2 w-100">
                              <Link to={'/order-tracking/' + order?.id} className="track-order-btn text-center" >
                                Track
                              </Link>
                            </div>
                            : null}
                        </div>

                        <div className="vertical-box-details">
                          <div className="vertical-box-head">
                            <div className="restaurant">
                              <h5 className="dark-text">{order?.shop?.shop_name}</h5>
                              <h5 className="theme-color">₹{order?.total}</h5>
                            </div>
                            <div className="my-2">
                              {order?.order_items?.length > 0 ? (
                                order.order_items.map((item) => (
                                  <div key={item.id}>
                                    {item.order_item_addons && item.order_item_addons.length > 0 ? (
                                      item.order_item_addons.map((addon) => (
                                        <h6 key={addon.id} className="food-items w-100" style={{ display: 'flex', justifyContent: 'space-between', fontSize: '14px', fontWeight: "600" }}>
                                          <span>{addon.addon_category_name} <span style={{ fontSize: '12px', fontWeight: "600" }}>({addon.addon_name})</span></span> x {addon.addon_quantity}
                                        </h6>
                                      ))
                                    ) : (
                                      <h6 className="food-items w-100" style={{ display: 'flex', justifyContent: 'space-between', fontSize: '14px', fontWeight: "600" }}>
                                        <span> {item.name}</span>  x {item.quantity}
                                      </h6>
                                    )}
                                  </div>
                                ))
                              ) : null}
                            </div>
                          </div>
                          <div className="reorder">
                            <h6 className="rating-star">
                              <ul className="timing">
                                <li>
                                  <span className="star">
                                    <i className="ri-star-s-fill"></i>
                                  </span>{" "}
                                  {parseFloat(order?.shop?.rating).toFixed(1)}
                                </li>
                              </ul>
                              <span className="text-end order fw-bolder mt-0" style={
                                order?.orderstatus_id == 1 ? ({ color: 'grey', fontSize: '14px' }
                                ) : order?.orderstatus_id == 2 ? ({ color: '#7d9ac4', fontSize: '14px' }
                                ) : order?.orderstatus_id == 3 ? ({ color: '#68bf9e', fontSize: '14px' }
                                ) : order?.orderstatus_id == 4 ? ({ color: '#4fbbd3', fontSize: '14px' }
                                ) : order?.orderstatus_id == 5 ? ({ color: '#419998', fontSize: '14px' }
                                ) : order?.orderstatus_id == 6 ? ({ color: '#7c47b9', fontSize: '14px' }
                                ) : order?.orderstatus_id == 7 ? ({ color: 'green', fontSize: '14px' }
                                ) : order?.orderstatus_id == 8 ? ({ color: 'red', fontSize: '14px' }
                                ) : order?.orderstatus_id == 9 ? ({ color: 'red', fontSize: '14px' }
                                ) : order?.orderstatus_id == 10 ? ({ color: '#b9602d', fontSize: '14px' }
                                ) : order?.orderstatus_id == 11 ? ({ color: '#b4ad4c', fontSize: '14px' }) : {}}  >
                                {order.order_status.name}
                              </span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
            :
            <div>
              <div className='' style={{ paddingTop: '8rem' }}>
                <Lottie
                  options={noOrders}
                  height={250}
                  width={250}
                />
                <div className="text-center" style={{ fontWeight: '500' }}>
                  No Orders Found
                </div>
              </div>
            </div>}
        </div>
      }
    </div>
  );
}

export default OrderHistory;
