import React, { useEffect, useState } from 'react'
import { Form, FormControl } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { applyCoupon, checkCouponValid, clearCoupon } from '../../../Redux/Store/cartReducer';
// import { IoMdClose } from 'react-icons/io';
// import ProgressiveImage from 'react-progressive-image';


const ApplyCoupon = () => {
    const dispatch = useDispatch();
    const [coupon, setCoupon] = useState([]);
    const [couponError, setCouponError] = useState(false);
    const [errorMessage, setErrorMessage] = useState([]);
    const cartProducts = useSelector((state) => state.cart);
    const viewCoupons = () => {
        window.location.href = "/list-coupons"
    }
    const onApplyCoupon = (e) => {
        e.preventDefault();
        const formData = new FormData();
        let cartProduct = JSON.stringify(cartProducts.orderitems)
        formData.append("user_id", localStorage.getItem('user_id'));
        formData.append("token", localStorage.getItem('device_token'));
        formData.append("coupon", coupon);
        formData.append("subtotal", cartProducts.totalAmount);
        formData.append("shop_id", cartProducts.shop_id);
        formData.append("cartProducts", cartProduct);

        dispatch(applyCoupon(formData)).then((response) => {
            if (response && response.payload && response.payload.success) {
                localStorage.setItem("NewCoupon", true)
                setCouponError(false)
                setErrorMessage([])
            } else {
                console.log('error')
                setCouponError(true)
                setErrorMessage(response.payload.message)
            }
        })
    };

    useEffect(() => {
        if (cartProducts?.appliedCoupon && cartProducts?.appliedCoupon !== null) {
            const formData = new FormData();
            let cartProduct = JSON.stringify(cartProducts.orderitems)
            formData.append("user_id", localStorage.getItem('user_id'));
            formData.append("token", localStorage.getItem('device_token'));
            formData.append("coupon", cartProducts?.appliedCoupon?.coupon_code);
            formData.append("subtotal", cartProducts.totalAmount);
            formData.append("shop_id", cartProducts.shop_id);
            formData.append("cartProducts", cartProduct);
            dispatch(checkCouponValid(formData)).then((response) => {
                if (response && response.payload && response.payload.success) {
                    console.log('coupon valid')
                } else {
                    dispatch(clearCoupon());
                }
            })
        }
    }, []);

    const removeCoupon = () => {
        dispatch(clearCoupon());
    }
    return (
        <div>
            {cartProducts?.appliedCoupon && cartProducts?.appliedCoupon?.used_amount > 0 ?
                <div class="custom-container mt-3">
                    <div className='applied-coupon'>
                        <div>
                            <h5 class="dark-text cart-coupon-text">Applid Coupon</h5>
                            <h6 class="coupon-code cart-coupon-text mt-2">{cartProducts?.appliedCoupon?.discount_type === "amount" ?
                                `Discount ${"₹" + parseFloat(cartProducts?.appliedCoupon?.used_amount)} applied`
                                :
                                `Discount ${parseInt(cartProducts?.appliedCoupon?.used_amount) + "%"} upto ${parseInt(cartProducts?.appliedCoupon?.upto)} applied`
                            }</h6>
                        </div>
                        <div className='flex items-center'>
                            <div className='px-2 cart-item-remove-round-btn' onClick={() => { removeCoupon() }}>
                                Remove
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div class="custom-container mt-3">
                    <div class="apply-coupon" onClick={() => { viewCoupons() }}>
                        <div>
                            <h5 class="dark-text">Apply Coupon</h5>
                            <h6 class="coupon-code"> </h6>
                        </div>
                        <i class="ri-arrow-right-s-line"></i>
                    </div>
                </div>
            }
            {couponError && <div className="text-danger text-center">{errorMessage}</div>}

        </div>
    )
}

export default ApplyCoupon
