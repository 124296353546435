import React, { useEffect, useState } from 'react'
import couponDiscount from '../../assets/icons/Offer-Discount.png';
import no_image from '../../assets/images/rp1.png';
import Items from './Items';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleStore } from '../../Redux/Store/storeReducer';
import { Link, useParams } from 'react-router-dom';
import Lottie from 'react-lottie';
import animationData from '../../assets/others/track-order-loading.json';
import FloatCart from '../FloatCart';
import ItemCategories from './ItemCategories';
import ProgressiveImage from 'react-progressive-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { addToCart, clearCart, removeFromCart } from '../../Redux/Store/cartReducer';
import { FaMinus, FaPlus } from 'react-icons/fa6';
import { IMAGE_BASE_URL } from '../../config';
import emptyItem from '../../assets/others/noItems.json';
import { HiOutlineMinus } from 'react-icons/hi2';
import { RiCloseCircleLine } from 'react-icons/ri';
import { SwipeableDrawer } from '@material-ui/core';
import Customization from '../Customization';

function SingleStore() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    let { id } = useParams();
    const [otherStore, setOtherStore] = useState(false);
    const [stateItem, setStateItem] = useState(null);
    const cartProducts = useSelector((state) => state.cart);
    const store = useSelector((state) => state.store.store_data.store);
    const categories = useSelector((state) => state.store.store_data.categories);
    const flash_items = useSelector((state) => state.store.store_data);


    useEffect(() => {
        const formData = new FormData();
        formData.append("user_id", localStorage.getItem("user_id"));
        formData.append("token", localStorage.getItem("device_token"));
        formData.append("store_id", id);
        dispatch(getSingleStore(formData)).then((response) => {
            if (response.payload.success) {
                setLoading(false);
                // console.log('loadingtrue')
            } else {
                setLoading(false);
                // console.log('loadingfalse')
            }
        }).catch((error) => {
            console.error("Fetching store failed", error);
            setLoading(false);
        });

        // No dependencies, so this effect should only run on mount
    }, []); // Empty dependency array indicates the effect runs once on mount




    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const emptyItems = {
        loop: true,
        autoplay: true,
        animationData: emptyItem,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <div>
            {loading ?
                <div className="flex items-center justify-center" style={{ paddingTop: '15rem' }}>
                    <Lottie
                        options={defaultOptions}
                        height={100}
                        width={100}
                    />
                </div>
                :
                <div style={{ paddingBottom: '6rem' }}>
                    <header>
                        <div class="header-panel-lg" style={store?.background_image ? { backgroundImage: `url(${IMAGE_BASE_URL + store?.background_image})` } : {}}>
                            <div class="custom-container">
                                <div class="panel">
                                    <span onClick={() => { window.history.back() }}><i class="ri-arrow-left-s-line"></i></span>
                                    <Link to='/search'><i class="ri-search-2-line"></i></Link>
                                </div>
                            </div>
                        </div>
                    </header>
                    <section>
                        <div class="custom-container">
                            <div class="restaurant-details-box">
                                <div class="restaurant-head">
                                    <div class="name">
                                        <ProgressiveImage
                                            delay={20}
                                            src={IMAGE_BASE_URL + store?.image ?? null}
                                            placeholder={no_image}
                                        >
                                            {(src) => (
                                                <img src={src}
                                                    style={{ borderRadius: '5px' }}
                                                    alt="deleevro"
                                                    class="img-fluid restaurant-logo"
                                                />
                                            )}
                                        </ProgressiveImage>
                                        <div class="d-block ms-2">
                                            <h3>{store?.shop_name}</h3>
                                            <h6>{store?.description}</h6>
                                        </div>
                                    </div>
                                    {/* <div class="option">
                                        <a href="#">
                                            <i class="ri-share-line share"></i>
                                        </a>

                                        <a href="#">
                                            <i class="toggle-heart ri-heart-3-fill heart"></i>
                                        </a>
                                    </div> */}
                                </div>

                                <div class="restaurant-details">
                                    <div class="location">
                                        <h6 class="rating-star align-items-center" style={{ border: 'none' }}>
                                            <span class="star"><i class="ri-star-s-fill"></i></span> 3.7
                                        </h6>
                                        {/* <h6 class="pt-2 light-text"><span class="dark-text">4.0km</span> Free Delivery</h6> */}
                                    </div>
                                    <ul class="distance">
                                        <li><i class="ri-map-pin-fill theme-color"></i> 1 km</li>
                                        <li><i class="ri-time-fill theme-color"></i> 15 min</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div class="custom-container">
                            <Swiper slidesPerView={1} spaceBetween={15}>
                                {store?.coupons?.map((coupon) => (
                                    <SwiperSlide>
                                        <div class="swiper-slide">
                                            <div class="offer-box">
                                                <div class="offer-icon ms-2">
                                                    <img class="img-fluid offer" src={couponDiscount} alt="offer" />
                                                </div>
                                                <div class="offer-content ms-3">
                                                    <h5>{coupon?.discount_type === "percentage" ? parseFloat(coupon?.used_amount) + "%" : "₹" + parseFloat(coupon?.used_amount)} OFF upto ₹{parseFloat(coupon?.upto)}</h5>
                                                    <h6 class="light-text">Use Code {coupon?.coupon_code}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </section>
                    {flash_items?.items?.length > 0 || categories?.filter(cp => cp?.products?.length > 0)?.length > 0 ?
                        <>
                            <div className='d-flex gap-2 px-3 pt-3 hidden-scroll-bar' style={{ overflowX: 'scroll' }}>
                                {flash_items && flash_items?.items?.map((item) => (
                                    <div className="products" style={{ minWidth: '40vw', maxWidth: '40vw' }}>
                                        <div className="product-box product-box-bg bg-white p-0">
                                            <ProgressiveImage
                                                delay={20}
                                                src={IMAGE_BASE_URL + item?.image}
                                                placeholder={no_image}
                                            >
                                                {(src) => (
                                                    <img
                                                        src={src}
                                                        style={{
                                                            height: '5.5rem',
                                                            width: '100%',
                                                            objectFit: 'cover',
                                                            borderRadius: "12px 12px 0px 0px"
                                                        }}
                                                        alt="deleevro"
                                                        className="img-fluid"
                                                    />
                                                )}
                                            </ProgressiveImage>
                                            <div className="product-box-detail" style={{ padding: '10px 6px' }}>
                                                <h5>{item.name}</h5>
                                                <div className="bottom-panel">
                                                    {item?.shop.is_offer && parseFloat(item?.shop.offer_percentage) > 0 ? (
                                                        <div className='flex flex-col items-center'>
                                                            <div className="price me-1" style={{ lineHeight: '14px' }}>
                                                                ₹{parseFloat(parseFloat(item.selling_price) - parseFloat(item.selling_price) * parseFloat(item?.shop.offer_percentage) / 100).toFixed(1)}
                                                            </div>
                                                            <div className="red-strike" style={{ fontSize: '13px', }}>
                                                                <s style={{ textDecorationColor: 'red' }}>
                                                                    ₹{parseFloat(item.selling_price).toFixed(1)}
                                                                </s>
                                                            </div>
                                                        </div>
                                                    ) : parseFloat(item.price) > parseFloat(item.selling_price) ? (
                                                        <div className='flex flex-col items-center'>
                                                            <div className="price me-1" style={{ lineHeight: '14px' }}>
                                                                ₹{parseFloat(item.selling_price).toFixed(1)}
                                                            </div>
                                                            <div className="red-strike" style={{ fontSize: '13px' }}>
                                                                <s style={{ textDecorationColor: 'red' }}>
                                                                    ₹{parseFloat(item.price).toFixed(1)}
                                                                </s>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="price">₹{parseFloat(item.selling_price).toFixed(1)}</div>
                                                    )}
                                                    {item?.out_of_stock == 'YES' ? (
                                                        <div className='text-danger'>Out of Stock</div>
                                                    ) : (
                                                        <>
                                                            {item?.addoncategories?.length > 0 ?
                                                                <Customization
                                                                    floatCart={(e) => this.floatCart(e)}
                                                                    product={item}
                                                                    cardView={true}
                                                                />
                                                                :
                                                                <>
                                                                    {cartProducts?.orderitems?.find((cp) => cp.id === item?.id) !== undefined ? (
                                                                        <div>
                                                                            {cartProducts?.orderitems?.filter((cp) => cp.id === item?.id).map((product) => (
                                                                                <div className="d-flex justify-content-between align-items-center" key={product.id} >
                                                                                    <div className="home-item-remove" onClick={() => {
                                                                                        let newData = Object.assign({}, product, {
                                                                                            quantity: 1,
                                                                                        });
                                                                                        dispatch(removeFromCart(newData));
                                                                                    }}>
                                                                                        <FaMinus />
                                                                                    </div>
                                                                                    <span className='px-1'>
                                                                                        {product.quantity}
                                                                                    </span>
                                                                                    <div className="home-item-add" onClick={() => {
                                                                                        let newData = Object.assign({}, product, {
                                                                                            quantity: 1,
                                                                                        });
                                                                                        dispatch(addToCart(newData));
                                                                                    }} >
                                                                                        <FaPlus />
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    ) :
                                                                        <div className="cart mb-0" onClick={() => {
                                                                            let newData = Object.assign({}, item, {
                                                                                quantity: 1,
                                                                            });
                                                                            if (cartProducts.orderitems.length === 0 || newData.shop_id === cartProducts?.shop_id) {
                                                                                dispatch(addToCart(newData))
                                                                            } else {
                                                                                setOtherStore(true);
                                                                                setStateItem(newData)
                                                                            }
                                                                            console.log(1234)
                                                                        }}>
                                                                            <i className="ri-shopping-cart-2-fill"></i>
                                                                        </div>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            {item?.shop.is_offer && parseFloat(item?.shop.offer_percentage) > 0 ?
                                                <div style={{ backgroundColor: '#7ac356', borderRadius: '0px 0px 10px 10px', textAlign: 'center', color: '#fff' }}>
                                                    Save {parseInt(item?.shop.offer_percentage)}%
                                                </div>
                                                : parseFloat(item.price) > parseFloat(item.selling_price) &&
                                                <div style={{ backgroundColor: '#7ac356', borderRadius: '0px 0px 10px 10px', textAlign: 'center', color: '#fff' }}>
                                                    Save ₹{item.price - item.selling_price}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {store?.shop_category_id === 2 ?
                                <ItemCategories categories={categories} />
                                :
                                <Items categories={categories} />
                            }
                        </>
                        :
                        <div className="d-flex flex-column align-items-center justify-center" >
                            <Lottie options={emptyItems} height={300} width={300} />
                            <div style={{ fontWeight: '550' }}>
                                No Items
                            </div>
                        </div>
                    }
                </div>
            }
            <FloatCart type={true} />

            <SwipeableDrawer
                anchor="bottom"
                open={otherStore}
                onClose={() => setOtherStore(false)}
                swipeAreaWidth={20}
                transitionDuration={500}
                style={otherStore ? { zIndex: '99999999' } : {}}
            >
                <div className="d-flex align-items-center justify-content-center w-100">
                    <HiOutlineMinus size={35} color={"#00000024"} />
                </div>
                <div className="" >
                    <div className='d-flex justify-content-between align-items-center px-3 pb-2 '>
                        <div className="font-22 fw-700" style={{ color: '#070648', }}>
                            <h1 className="mt-2 mb-0 font-weight-black h5">
                                Replace items in the cart?
                            </h1>
                        </div>
                        <div onClick={() => { setOtherStore(false) }}>
                            <RiCloseCircleLine size={25} />
                        </div>
                    </div>
                    <div>
                        <div className="px-3 col-12 pb-3 d-flex justify-content-between align-items-center">
                        </div>
                        <div className="px-3 mb-20">
                            Your cart contains items from other
                            store. Do you want to discard the
                            selection and add items from this store?
                        </div>
                        <div className="d-flex justify-content-around w-100 px-3 mt-5  mb-3">
                            <div className="text-center me-4 w-100">
                                <button
                                    className="single-product-no py-3 px-2 w-100 position-relative"
                                    onClick={() => { setOtherStore(false) }}
                                >
                                    NO
                                </button>
                            </div>
                            <div className="text-center w-100">
                                <button
                                    className="single-product-yes py-3 px-2 w-100 text-center text-white position-relative"
                                    onClick={() => {
                                        dispatch(clearCart())
                                        setOtherStore(false)
                                        dispatch(addToCart(stateItem));
                                    }}
                                >
                                    YES
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </SwipeableDrawer>
        </div>
    )
}

export default SingleStore