import { Link } from "react-router-dom";

function TopBar() {


    return (
        <header className="section-t-space">
            <div className="custom-container">
                <div className="header">
                    <div className="head-content flex-grow-1">
                        <div className="header-location w-100 flex-grow-1">
                            <Link to="/location" className="w-100 flex-grow-1">
                                <h2 className="ellipsis-one-line w-100">
                                    {JSON.parse(localStorage.getItem('userSetAddress')).address ?? ''}
                                </h2>
                                <i className="ri-arrow-down-s-line d-arrow"></i>
                            </Link>
                        </div>
                    </div>
                    {/* <a href="#">
                        <i className="ri-notification-2-fill"></i>
                    </a> */}
                </div>
            </div>
        </header>
    );
}

export default TopBar