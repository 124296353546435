
// export const WEBSITE_API_URL = 'http://192.168.1.51:8101/api'
// export const WEBSITE_API_URL = 'http://127.0.0.1:8000/api'
// export const WEBSITE_API_URL = 'https://deleevro.howincloud.com/api'
export const WEBSITE_API_URL = 'https://apps.deleevro.com/api'

// export const IMAGE_BASE_URL = 'http://192.168.1.51:8101/'
// export const IMAGE_BASE_URL = 'http://127.0.0.1:8000'
// export const IMAGE_BASE_URL = 'https://deleevro.howincloud.com/public'
export const IMAGE_BASE_URL = 'https://apps.deleevro.com'

