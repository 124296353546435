
import React from 'react'
// import nonVeg from '../../../assets/svg/nonveg.svg';
// import veg from '../../../assets/icons/veg.svg';
// import { useDispatch, useSelector } from 'react-redux';
// import { addToCart, removeFromCart } from '../../../Redux/Store/cartReducer'; 
import ProgressiveImage from 'react-progressive-image';
import { Link } from 'react-router-dom';
import no_image from '../../../assets/images/rp1.png'
import { IMAGE_BASE_URL } from '../../../config';

function ItemCategories({ categories }) {

    return (
        <div>
            <section class="food-list-section section-b-space">
                <div class="custom-container">
                    {categories && categories?.length > 0 ?
                        <div className="d-grid gap-2 hidden-scroll-bar pt-3" style={{ gridTemplateColumns: "repeat(4 ,1fr)", overflowX: 'hidden' }}   >
                            {categories?.map((category) => (
                                <Link to={'/category-products/' + category.id}>
                                    <div className='d-flex flex-column justify-content-center align-items-center'>
                                        <div>
                                            <ProgressiveImage
                                                delay={20}
                                                src={IMAGE_BASE_URL + category?.image ?? null}
                                                placeholder={no_image}
                                            >
                                                {(src) => (
                                                    <img
                                                        src={src}
                                                        alt="deleevro"
                                                        style={{ width: "17vw", minWidth: "17vw", height: "17vw", "objectFit": "cover", "borderRadius": "50%" }}
                                                        class="img-fluid profile-pic"
                                                    />
                                                )}
                                            </ProgressiveImage>
                                        </div>
                                        <h6 className='mt-2'>{category?.name}</h6>
                                    </div>
                                </Link>
                            ))}
                        </div>
                        : <div>
                            No Item
                        </div>
                    }

                </div>
            </section>
        </div>

    )
}

export default ItemCategories