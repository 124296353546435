import React from 'react'

function Emptysection
  () {
  return (
    <div>
      <section className="empty-section section-t-space section-b-space" >
        <div className="custom-container " >
          <h2>Love Your Hunger!</h2>
          <h5>Crafted with in Kerala, India</h5>
        </div>
      </section>

    </div>
  )
}

export default Emptysection
