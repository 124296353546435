
import { useEffect, useState } from 'react';
import { BsCart3 } from 'react-icons/bs';
import { FiShoppingBag } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { calcualteShopMaxDistance, calculateShopChargeTax } from '../../Redux/Store/cartReducer';
import { Link } from 'react-router-dom';

function FloatCart({ type }) {
    const cartProducts = useSelector((state) => state.cart);
    const shop_info = useSelector((state) => state.cart?.restaurant_info?.data?.restaurant);

    const totalPrice = () => {
        return "₹" + parseFloat(cartProducts?.totalPayableAmount).toFixed(2)
    }

    const itemCount = () => {
        let count = 0
        if (cartProducts?.orderitems?.length > 0) {
            cartProducts?.orderitems?.map((orderItem) => {
                if (orderItem?.selectedaddons?.length > 0) {
                    count += orderItem.selectedaddons.length;
                } else {
                    count += 1;
                }
            })
            return parseInt(count);
        } else {
            return count;
        }
    }


    return (
        <>
            {cartProducts?.orderitems?.length > 0 ?
                <div class="cart-popup">
                    <div class="price-items">
                        <h3>{totalPrice()}</h3>
                        <h6>{itemCount()} item Added</h6>
                    </div>
                    <Link to={"/cart"} class="btn theme-btn cart-btn mt-0">
                        View Cart
                    </Link>
                </div>
                : null}
        </>

    )
}

export default FloatCart