import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { WEBSITE_API_URL } from "../../config";

const initialState = {
    wallet_data: [],
    isSuccess: false,
    message: "",
    loading: false,
};

export const getWallet = createAsyncThunk("store/getWallet", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/get-wallet", formData);
        return response.data;
    } catch (error) {
        console.log('error2', error);
        throw error;
    }
});

const walletSlice = createSlice({
    name: "wallet",
    initialState: initialState,
    reducers: {
        walletCurrentTenant: (state, action) => {
            state.tenant = action.payload;
        },
    },
    extraReducers: {
        [getWallet.pending]: (state,) => {
            state.loading = true;
        },
        [getWallet.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.wallet_data = payload;
            state.isSuccess = true;
        },
        [getWallet.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },
    },
});


export const { walletCurrentTenant } = walletSlice.actions;

export default walletSlice.reducer;
