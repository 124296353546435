import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { WEBSITE_API_URL } from "../../config";

const initialState = {
    search_data: [],
    isSuccess: false,
    message: "",
    loading: false,
};

export const getAllSearchData = createAsyncThunk("store/getAllSearchData", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/get-all-search-datas", formData);
        return response.data;
    } catch (error) {
        console.log('error', error);
        throw error;
    }
});


const searchSlice = createSlice({
    name: "search",
    initialState: initialState,
    reducers: {
        searchCurrentTenant: (state, action) => {
            state.tenant = action.payload;
        },
    },
    extraReducers: {
        [getAllSearchData.pending]: (state,) => {
            state.loading = true;
        },
        [getAllSearchData.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.search_data = payload;
            state.isSuccess = true;
        },
        [getAllSearchData.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },
    },
});


export const { searchCurrentTenant } = searchSlice.actions;

export default searchSlice.reducer;
