import React from 'react'
import ProgressiveImage from 'react-progressive-image';
import { useSelector } from 'react-redux';
import { IMAGE_BASE_URL } from '../../../../config';
import { Link } from 'react-router-dom';
import no_image from '../../../../assets/images/rp1.png'

function Categorylist() {
  const foodCategories = useSelector((state) => state.home?.home_data?.data?.foodCategories);

  return (
    <div>
      <header class="section-t-space">
        <div class="custom-container">
          <div class="header-panel">
            <a href="home">
              <i class="ri-arrow-left-s-line"></i>
            </a>
            <h2>Food Categories</h2>
          </div>
        </div>
      </header>
      <section class="categories section-lg-b-space">
        <div class="custom-container">
          <div class="row ratio_square">
            {foodCategories && foodCategories?.length > 0 ?
              foodCategories?.map((category) =>
                <div class="col-4 mt-2">
                  <Link to={'/single-category/FOOD/' + category.id}>
                    <div class="categories">
                      <ProgressiveImage
                        delay={20}
                        src={IMAGE_BASE_URL + category.image}
                        placeholder={no_image}
                      >
                        {(src) => (
                          <img
                            src={src}
                            style={{
                              borderRadius: '10px',
                              width: '100%',
                              height: '5.5rem'
                            }}
                            alt="deleevro"
                          />
                        )}
                      </ProgressiveImage>
                    </div>
                    <h6>{category?.name}</h6>
                  </Link>
                </div>
              )
              :
              <div>
                No Items Found
              </div>
            }
          </div>
        </div>
      </section>
    </div>
  )
}

export default Categorylist