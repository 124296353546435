import React from "react";
import { Link } from "react-router-dom";

function Voucher() {
  return (
    <div>
      {/* <!-- header start --> */}
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header-panel">
            <Link to={'/profile'}>
              <i className="ri-arrow-left-s-line"></i>
            </Link>
            <h2>My Voucher</h2>
          </div>
        </div>
      </header>
      {/* <!-- header end --> */}

      {/* <!-- tab section start --> */}
      <section className="section-t-space">
        <div className="custom-container">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="new-tab"
                data-bs-toggle="pill"
                data-bs-target="#new"
                type="button"
                role="tab"
              >
                New
              </button>
            </li>

            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="used-tab"
                data-bs-toggle="pill"
                data-bs-target="#used"
                type="button"
                role="tab"
              >
                Used
              </button>
            </li>

            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="expired-tab"
                data-bs-toggle="pill"
                data-bs-target="#expired"
                type="button"
                role="tab"
              >
                Expired
              </button>
            </li>
          </ul>

          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="new"
              role="tabpanel"
              tabindex="0"
            >
              <section className="section-b-space">
                <div className="row g-3 gx-3">
                  <div className="col-6">
                    <div className="voucher-box">
                      <div className="voucher-img">
                        <img
                          className="img-fluid voucher-icon"
                          src="assets/images/icons/c1.png"
                          alt="c1"
                        />
                      </div>
                      <div className="voucher-content">
                        <h5>Dunkin Donuts</h5>
                        <p>Buy 1 donut and get 20% off on second donut</p>
                      </div>
                      <div className="voucher-discount color-5">
                        <img
                          className="img-fluid top-vector"
                          src="assets/images/svg/vvt.svg"
                          alt="top-design"
                        />
                        <h2>20% OFF</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="voucher-box">
                      <div className="voucher-img">
                        <img
                          className="img-fluid voucher-icon"
                          src="assets/images/icons/brand1.png"
                          alt="brand1"
                        />
                      </div>
                      <div className="voucher-content">
                        <h5>La Pino’z</h5>
                        <p>Buy 1 pizza and get 50% off on second pizza</p>
                      </div>
                      <div className="voucher-discount color-2">
                        <img
                          className="img-fluid top-vector"
                          src="assets/images/svg/vvt.svg"
                          alt="top-design"
                        />
                        <h2>50% OFF</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="voucher-box">
                      <div className="voucher-img">
                        <img
                          className="img-fluid voucher-icon"
                          src="assets/images/icons/c3.png"
                          alt="c3"
                        />
                      </div>
                      <div className="voucher-content">
                        <h5>KFC Chicken</h5>
                        <p>Buy 1 order and get 30% off on second order</p>
                      </div>
                      <div className="voucher-discount color-3">
                        <img
                          className="img-fluid top-vector"
                          src="assets/images/svg/vvt.svg"
                          alt="top-design"
                        />
                        <h2>30% OFF</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="voucher-box">
                      <div className="voucher-img">
                        <img
                          className="img-fluid voucher-icon"
                          src="assets/images/icons/brand9.png"
                          alt="c1"
                        />
                      </div>
                      <div className="voucher-content">
                        <h5>Taco Bell</h5>
                        <p>Buy 1 order and get 10% off on second order</p>
                      </div>
                      <div className="voucher-discount color-4">
                        <img
                          className="img-fluid top-vector"
                          src="assets/images/svg/vvt.svg"
                          alt="top-design"
                        />
                        <h2>10% OFF</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="voucher-box">
                      <div className="voucher-img">
                        <img
                          className="img-fluid voucher-icon"
                          src="assets/images/icons/brand2.png"
                          alt="brand2"
                        />
                      </div>
                      <div className="voucher-content">
                        <h5>McDonals</h5>
                        <p>Buy 1 burger and get 50% off on second burger</p>
                      </div>
                      <div className="voucher-discount color-1">
                        <img
                          className="img-fluid top-vector"
                          src="assets/images/svg/vvt.svg"
                          alt="top-design"
                        />
                        <h2>50% OFF</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  href="profile"
                  className="btn theme-btn add-select w-100"
                  role="button"
                >
                  Use Now
                </a>
              </section>
            </div>
            <div className="tab-pane fade" id="used" role="tabpanel" tabindex="0">
              <section className="section-b-space">
                <div className="row g-3 gx-3">
                  <div className="col-6">
                    <div className="voucher-box">
                      <div className="voucher-img">
                        <img
                          className="img-fluid voucher-icon"
                          src="assets/images/icons/c1.png"
                          alt="c1"
                        />
                      </div>
                      <div className="voucher-content">
                        <h5>Dunkin Donuts</h5>
                        <p>Buy 1 donut and get 20% off on second donut</p>
                      </div>
                      <div className="voucher-discount color-5">
                        <img
                          className="img-fluid top-vector"
                          src="assets/images/svg/vvt.svg"
                          alt="top-design"
                        />
                        <h2>20% OFF</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="voucher-box">
                      <div className="voucher-img">
                        <img
                          className="img-fluid voucher-icon"
                          src="assets/images/icons/brand1.png"
                          alt="brand1"
                        />
                      </div>
                      <div className="voucher-content">
                        <h5>La Pino’z</h5>
                        <p>Buy 1 pizza and get 50% off on second pizza</p>
                      </div>
                      <div className="voucher-discount color-2">
                        <img
                          className="img-fluid top-vector"
                          src="assets/images/svg/vvt.svg"
                          alt="top-design"
                        />
                        <h2>50% OFF</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="voucher-box">
                      <div className="voucher-img">
                        <img
                          className="img-fluid voucher-icon"
                          src="assets/images/icons/c3.png"
                          alt="c3"
                        />
                      </div>
                      <div className="voucher-content">
                        <h5>KFC Chicken</h5>
                        <p>Buy 1 order and get 30% off on second order</p>
                      </div>
                      <div className="voucher-discount color-3">
                        <img
                          className="img-fluid top-vector"
                          src="assets/images/svg/vvt.svg"
                          alt="top-design"
                        />
                        <h2>30% OFF</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="voucher-box">
                      <div className="voucher-img">
                        <img
                          className="img-fluid voucher-icon"
                          src="assets/images/icons/brand9.png"
                          alt="c1"
                        />
                      </div>
                      <div className="voucher-content">
                        <h5>Taco Bell</h5>
                        <p>Buy 1 order and get 10% off on second order</p>
                      </div>
                      <div className="voucher-discount color-4">
                        <img
                          className="img-fluid top-vector"
                          src="assets/images/svg/vvt.svg"
                          alt="top-design"
                        />
                        <h2>10% OFF</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="voucher-box">
                      <div className="voucher-img">
                        <img
                          className="img-fluid voucher-icon"
                          src="assets/images/icons/brand2.png"
                          alt="brand2"
                        />
                      </div>
                      <div className="voucher-content">
                        <h5>McDonals</h5>
                        <p>Buy 1 burger and get 50% off on second burger</p>
                      </div>
                      <div className="voucher-discount color-1">
                        <img
                          className="img-fluid top-vector"
                          src="assets/images/svg/vvt.svg"
                          alt="top-design"
                        />
                        <h2>50% OFF</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div
              className="tab-pane fade"
              id="expired"
              role="tabpanel"
              tabindex="0"
            >
              <section className="section-b-space">
                <div className="row g-3 gx-3">
                  <div className="col-6">
                    <div className="expired-box">
                      <div className="expired-tag">
                        <p>Expired</p>
                      </div>
                      <div className="voucher-box expired-voucher">
                        <div className="voucher-img">
                          <img
                            className="img-fluid voucher-icon"
                            src="assets/images/icons/c1.png"
                            alt="c1"
                          />
                        </div>
                        <div className="voucher-content">
                          <h5>Dunkin Donuts</h5>
                          <p>Buy 1 donut and get 20% off on second donut</p>
                        </div>
                        <div className="voucher-discount">
                          <img
                            className="img-fluid top-vector"
                            src="assets/images/svg/vvt.svg"
                            alt="top-design"
                          />
                          <h2>20% OFF</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="expired-box">
                      <div className="expired-tag">
                        <p>Expired</p>
                      </div>
                      <div className="voucher-box expired-voucher">
                        <div className="expired-tag">
                          <p>Expired</p>
                        </div>
                        <div className="voucher-img">
                          <img
                            className="img-fluid voucher-icon"
                            src="assets/images/icons/brand1.png"
                            alt="brand1"
                          />
                        </div>
                        <div className="voucher-content">
                          <h5>La Pino’z</h5>
                          <p>Buy 1 pizza and get 50% off on second pizza</p>
                        </div>
                        <div className="voucher-discount">
                          <img
                            className="img-fluid top-vector"
                            src="assets/images/svg/vvt.svg"
                            alt="top-design"
                          />
                          <h2>50% OFF</h2>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="expired-box">
                      <div className="expired-tag">
                        <p>Expired</p>
                      </div>

                      <div className="voucher-box expired-voucher">
                        <div className="expired-tag">
                          <p>Expired</p>
                        </div>
                        <div className="voucher-img">
                          <img
                            className="img-fluid voucher-icon"
                            src="assets/images/icons/c3.png"
                            alt="c3"
                          />
                        </div>
                        <div className="voucher-content">
                          <h5>KFC Chicken</h5>
                          <p>Buy 1 order and get 30% off on second order</p>
                        </div>
                        <div className="voucher-discount">
                          <img
                            className="img-fluid top-vector"
                            src="assets/images/svg/vvt.svg"
                            alt="top-design"
                          />
                          <h2>30% OFF</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- tab section end --> */}
    </div>
  );
}

export default Voucher;
