import React, { useEffect, useState } from 'react'
import { getSingleOrder } from '../../../Redux/Store/orderReducer'
import { useDispatch } from 'react-redux';
import Lottie from 'react-lottie';
import animationData from '../../../assets/others/track-order-loading.json'
import cube from '../../../assets/svg/cube.svg'
import cube2 from '../../../assets/svg/3d-cube.svg'
import bike from '../../../assets/svg/Scooter.svg'
import done from '../../../assets/svg/done.svg'
import close from '../../../assets/svg/cancel.svg'
import dots from '../../../assets/svg/dots-design.svg'
import delivery from '../../../assets/images/delivery.png'
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';


function Ordertracking() {
  const dispatch = useDispatch();
  const [orders, setOrders] = useState()
  const [loading, setLoading] = useState(true)
  let { id } = useParams();
  useEffect(() => {
    const formData = new FormData();
    formData.append("token", localStorage.getItem("device_token"));
    formData.append("order_id", id);
    dispatch(getSingleOrder(formData)).then((response) => {
      if (response && response.payload && response.payload.success) {
        setOrders(response.payload.order ? response.payload.order : [])
        setLoading(false)
      } else {
        setLoading(false)
        console.log('error')
      }
    })
  }, [])


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };


  return (
    <div>
      {loading ?
        <div className='' style={{ paddingTop: '15rem' }}>
          <Lottie
            options={defaultOptions}
            height={100}
            width={100}
          />
        </div> : <div>

       
      <header class="section-t-space">
        <div class="custom-container">
          <div class="header-panel">
            <Link to={'/order-history'}>
              <i class="ri-arrow-left-s-line"></i>
            </Link>
            <h2>Order Tracking</h2>
          </div>
        </div>
      </header>
      <section>
        <div class="custom-container">
          <h5 class="dark-text"><span class="light-text">Order ID : </span>{orders?.unique_order_id}</h5>
          <div class="order-tracking mt-3">
            <ul class="tracking-place">

              <li class="active">
                <h6 style={{ width: '4rem', marginLeft: '10px' }}>{orders?.order_placed_at ? moment(orders?.order_placed_at).format('hh:mm A') : ' -- : -- -- '}</h6>
                <span></span>
                <img class="img-fluid ms-3 icon step-1" src={cube} alt="cube" />
                <h6 class="color-1 ms-2">Order Placed</h6>
              </li>
              {orders?.orderstatus_id !== 8 ?
                <li className={orders?.orderstatus_id >= 3 ? 'active' : ''}>
                  <h6 style={{ width: '4rem', marginLeft: '10px' }}>{orders?.order_cook_at ? moment(orders?.order_cook_at).format('hh:mm A') : ' -- : -- -- '}</h6>
                  <span></span>
                  <img class="img-fluid ms-3 icon step-2" src={cube2} alt="3d-cube" />

                  <h6 class="color-2 ms-2">Preparing food</h6>
                </li> : null}
              {orders?.orderstatus_id !== 8 ?
                <li className={orders?.orderstatus_id >= 6 ? 'active' : ''}>
                  <h6 style={{ width: '4rem', marginLeft: '10px' }}>{orders?.order_picked_up_at ? moment(orders?.order_picked_up_at).format('hh:mm A') : ' -- : -- -- '}</h6>
                  <span></span>
                  <img class="img-fluid ms-3 icon step-5" src={bike} alt="bike" />

                  <h6 class="color-5 ms-2">Food on the way</h6>
                </li> : null}
              <li className={orders?.orderstatus_id === 8 ? 'active-danger p-0' : orders?.orderstatus_id === 7 ? 'active p-0' : 'p-0'}>
                <h6 style={{ width: '4rem', marginLeft: '10px' }}>
                  {orders?.orderstatus_id === 8 ? orders?.order_cancelled_at ? moment(orders?.order_cancelled_at).format('hh:mm A') : ' -- : -- -- ' :
                    orders?.orderstatus_id === 7 ?
                      orders?.order_delivered_at ? moment(orders?.order_delivered_at).format('hh:mm A') : ' -- : -- -- ' : ' -- : -- -- '} </h6>
                <span></span>
                {orders?.orderstatus_id === 8 ?
                  <img class="img-fluid ms-3 icon step-3" src={close} alt="done" />
                  :
                  <img class="img-fluid ms-3 icon step-4" src={done} alt="done" />
                }
                <h6 class={orders?.orderstatus_id === 8 ? "color-3 ms-2" : "color-4 ms-2"}>{orders?.orderstatus_id === 8 ? "Delivery Cancelled" : "Delivery to you"}</h6>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section class="bill-details section-lg-b-space">
        <div class="custom-container">
          <div class="total-detail mt-3">
            <div class="sub-total pb-3">
              <h5>Sub Total</h5>
              <h5 class="fw-semibold ">₹{orders?.sub_total}</h5>
            </div>
            <div class="sub-total pb-3">
              <h5>Delivery Charge  </h5>
              <h5 class="fw-semibold">₹{orders?.delivery_charge > 0 ? orders?.delivery_charge : <span style={{ color: 'green' }}>Free</span>}</h5>
            </div>

            <div class="sub-total pb-3">
              <h5>Tax ({parseFloat(orders?.tax_amount*100/orders?.sub_total).toFixed(2)}%)</h5>
              {parseFloat(orders?.tax_amount) > 0 ?
                <h5 class="fw-semibold">₹{orders?.tax_amount}</h5>
                :
                <h5 class="fw-semibold">₹0.00</h5>
              }
            </div>
            <div class="sub-total pb-3">
              <h5>Discount</h5>
              {parseFloat(orders?.coupon_amount) > 0 ?
                <h5 class="fw-semibold">₹{orders?.coupon_amount}</h5>
                :
                <h5 class="fw-semibold">₹0.00</h5>
              }
            </div>
            <div class="grand-total">
              <h5 class="fw-semibold">Grand Total</h5>
              <h5 class="fw-semibold amount">₹{orders?.total} </h5>
            </div>
            <img class="dots-design" src={dots} alt="dots" />
          </div>
          <div class="delivery-time mt-4">
            <p class="delivery-line mb-0 m-0 p-0">A Moments of Delivered on Time</p>
            <img class="img-fluid delivery-bike" src={delivery} alt="delivery" />
          </div>
        </div>
      </section>
      </div>}

    </div>
  )
}

export default Ordertracking