import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios'
import { WEBSITE_API_URL } from "../../config";


const initialState = {
    orderitems: [],
    appliedCoupon: [],
    shop_id: [],
    shop_info: [],
    shop_tax: [],
    coupon_valid: [],
    shop_distance: [],
    totalAmount: 0,
    tax: 0,
    deliveryCharge: 0,
    discount: 0,
    compelete_order: [],
    amountToBeReturned: 0,
    totalPayableAmount: 0,
    paymentMethod: "Cash",
};

export const applyCoupon = createAsyncThunk("shop/applyCoupon", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/apply-coupon", formData);
        return response.data;
    } catch (error) {
        console.log('error2', error);
        throw error;
    }
});

export const placeOrder = createAsyncThunk("shop/placeOrder", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/place-order", formData);
        return response.data;
    } catch (error) {
        console.log('error2', error);
        throw error;
    }
});

export const getShopDistance = createAsyncThunk("shop/getShopDistance", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/get-shop-distance", formData);
        return response.data;
    } catch (error) {
        console.log('error2', error);
        throw error;
    }
});

export const getCartShop = createAsyncThunk("shop/getCartshop", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/get-cart-shop", formData);
        return response.data;
    } catch (error) {
        console.log('error2', error);
        throw error;
    }
});

export const checkCouponValid = createAsyncThunk("shop/checkCouponValid", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/check-coupon-valid", formData);
        return response.data;
    } catch (error) {
        console.log('error2', error);
        throw error;
    }
});

export const calcualteShopMaxDistance = createAsyncThunk("shop/calcualteShopMaxDistance", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/calcualte-shop-max-distance", formData);
        return response.data;
    } catch (error) {
        console.log('error2', error);
        throw error;
    }
});


export const calculateShopChargeTax = createAsyncThunk("shop/calculateShopChargeTax", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/calcualte-shop-charge-tax", formData);
        return response.data;
    } catch (error) {
        console.log('error2', error);
        throw error;
    }
});

export const getAllCartCoupons = createAsyncThunk("shop/getAllCartCoupons", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/get-all-cart-coupons", formData);
        return response.data;
    } catch (error) {
        console.log('error2', error);
        throw error;
    }
});
const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addToCart: (state, action) => {
            const { orderitems, totalAmount } = state;
            const { id, name, selling_price, description, shop, price, food_type, image, quantity } = action.payload;
            const item = orderitems.find((item) => item.id === id);
            let currentTotal = totalAmount;
            let itemDiscount = 0;
            if (item) {
                let sum = 0;
                item.quantity += 1;
                if (shop.is_offer && parseFloat(shop.offer_percentage) > 0) {
                    const discountedPrice = parseFloat(parseFloat(selling_price) - parseFloat(selling_price) * parseFloat(shop.offer_percentage) / 100).toFixed(1);
                    itemDiscount = parseFloat(selling_price) - discountedPrice;
                } else if (parseFloat(price) > parseFloat(selling_price)) {
                    itemDiscount = parseFloat(price) - parseFloat(selling_price);
                } else {
                    itemDiscount = 0;
                }
                sum = parseFloat((item.selling_price * item.quantity).toFixed(2));
                item.totalPrice = sum;
                currentTotal = parseFloat(currentTotal) + parseFloat(selling_price);
                // state.tax = parseFloat((currentTotal * (shop?.tax / 100)).toFixed(2));
            } else {
                let sum = 0;
                if (parseFloat(price) - parseFloat(selling_price) > 0) {
                    itemDiscount = parseFloat(price) - parseFloat(selling_price);
                }

                if (shop.is_offer && parseFloat(shop.offer_percentage) > 0) {
                    const discountedPrice = parseFloat(parseFloat(selling_price) - parseFloat(selling_price) * parseFloat(shop.offer_percentage) / 100).toFixed(1);
                    itemDiscount = parseFloat(selling_price) - discountedPrice;
                    sum = parseFloat((parseFloat(parseFloat(selling_price) - parseFloat(itemDiscount)) * quantity).toFixed(2));
                } else if (parseFloat(price) > parseFloat(selling_price)) {
                    itemDiscount = parseFloat(price) - parseFloat(selling_price);
                    sum = parseFloat((selling_price * quantity).toFixed(2));
                } else {
                    itemDiscount = 0;
                    sum = parseFloat((selling_price * quantity).toFixed(2));
                }

                orderitems.push({
                    id: id,
                    name: name,
                    price: price,
                    image: image,
                    description: description,
                    food_type: food_type,
                    shop: shop,
                    quantity: 1,
                    discount: itemDiscount,
                    selling_price: sum,
                });
                currentTotal = parseFloat(currentTotal) + parseFloat(sum);
                // state.tax = parseFloat((currentTotal * (shop?.tax / 100)).toFixed(2));
            }
            state.shop_id = shop.id;
            state.totalAmount = parseFloat(currentTotal);
            // state.totalPayableAmount = parseFloat((currentTotal + state.tax).toFixed(2));
            state.totalPayableAmount = parseFloat((currentTotal).toFixed(2));
        },
        removeFromCart: (state, action) => {
            const { orderitems, totalAmount } = state;
            const { id, isRemoveAll } = action.payload;
            const item = orderitems.find((item) => item.id === id);
            let currentTotal = totalAmount;
            if (item) {
                if (isRemoveAll) {
                    currentTotal -= item.totalPrice;
                    const index = orderitems.indexOf(item);
                    orderitems.splice(index, 1);
                    if (orderitems.length === 0) {
                        currentTotal = 0;
                    }
                    state.shop_id = [];
                    state.tax = 0;
                } else {
                    item.quantity -= 1;
                    let totalPrice = 0;
                    totalPrice = parseFloat((item.selling_price * item.quantity).toFixed(2));
                    item.totalPrice = totalPrice;
                    currentTotal -= item.selling_price;

                    if (item.quantity === 0) {
                        const index = orderitems.indexOf(item);
                        orderitems.splice(index, 1);
                        if (orderitems.length === 0) {
                            currentTotal = 0;
                        }
                    }
                    // state.tax = parseFloat((currentTotal * (item.shop?.tax / 100)).toFixed(2));
                }
                state.totalAmount = parseFloat(currentTotal);
                // state.totalPayableAmount = parseFloat((currentTotal + state.tax).toFixed(2));
                state.totalPayableAmount = parseFloat((currentTotal).toFixed(2));
            }
        },
        addCustomizationCart: (state, action) => {
            const { orderitems, totalAmount } = state;
            const { id, name, description, shop, price, image, food_type, quantity, selectedaddons } = action.payload;
            const item = orderitems.find((item) => item.id === id);
            let currentTotal = totalAmount;
            if (item) {
                let newPrice = 0;
                if (item.selectedaddons && item.selectedaddons.length > 0) {
                    item.selectedaddons.map((addon) => {
                        const addon_selected = selectedaddons.find((selAdon) => selAdon.addon_id === addon.addon_id)
                        const addon_not_selected = selectedaddons.filter((selAdon) => selAdon.addon_id !== addon.addon_id)
                        if (addon_selected) {
                            addon.quantity += quantity;
                            addon.totalPrice = addon.quantity * parseFloat(addon.singlePrice);
                            newPrice = quantity * parseFloat(addon.singlePrice);
                            const index = item.selectedaddons.findIndex((p) => p.addon_id === addon.addon_id);
                            item.selectedaddons[index] = { ...item.selectedaddons[index], quantity: addon.quantity, totalPrice: addon.totalPrice }
                            currentTotal = parseFloat(currentTotal) + newPrice;
                        }
                        if (addon_not_selected) {
                            addon_not_selected.map((addon_not) => {
                                if (addon_not.addon_id !== addon.addon_id) {
                                    const index = item.selectedaddons.findIndex((cp) => cp.addon_id === addon_not.addon_id)
                                    if (index == -1) {
                                        let totalPrice = parseFloat(addon_not.totalPrice);
                                        item.selectedaddons.push(addon_not);
                                        currentTotal = parseFloat(currentTotal) + parseFloat(totalPrice);
                                        // state.tax = parseFloat((currentTotal * (shop?.tax / 100)).toFixed(2));
                                    }
                                }
                            })
                        }
                    })
                } else {
                    let totalPrice = 0;
                    totalPrice += selectedaddons.reduce((AddonPrice, p) => {
                        AddonPrice += parseFloat(p.totalPrice);
                        return (AddonPrice)
                    }, 0);
                    orderitems.push({
                        id: id,
                        name: name,
                        price: price,
                        image: image,
                        description: description,
                        selectedaddons: selectedaddons,
                        food_type: food_type,
                        shop: shop,
                    });
                    currentTotal = parseFloat(currentTotal) + parseFloat(totalPrice);
                    // state.tax = parseFloat((currentTotal * (shop?.tax / 100)).toFixed(2));
                }
            } else {
                let totalPrice = 0;
                totalPrice += selectedaddons.reduce((AddonPrice, p) => {
                    AddonPrice += parseFloat(p.totalPrice);
                    return (AddonPrice)
                }, 0);
                orderitems.push({
                    id: id,
                    name: name,
                    price: price,
                    image: image,
                    description: description,
                    selectedaddons: selectedaddons,
                    food_type: food_type,
                    shop: shop,
                });
                currentTotal = parseFloat(currentTotal) + parseFloat(totalPrice);
                // state.tax = parseFloat((currentTotal * (shop?.tax / 100)).toFixed(2));
            }
            // state.tax = parseFloat((currentTotal * (shop?.tax / 100)).toFixed(2));
            state.shop_id = shop.id;
            state.totalAmount = parseFloat(currentTotal);
            state.totalPayableAmount = parseFloat((currentTotal).toFixed(2));
            state.totalPayableAmount = parseFloat((currentTotal + state.tax).toFixed(2));
        },
        addAddonsCart: (state, action) => {
            const { orderitems, totalAmount } = state;
            const { id, shop, addAddonId } = action.payload;
            const item = orderitems.find((item) => item.id === id);
            let currentTotal = totalAmount;
            if (item) {
                if (item.selectedaddons && item.selectedaddons.length > 0) {
                    item.selectedaddons.map((addon) => {
                        if (addon.addon_id == addAddonId) {
                            addon.quantity += 1;
                            addon.totalPrice = addon.quantity * parseFloat(addon.singlePrice);
                            const index = item.selectedaddons.findIndex((p) => p.addon_id === addon.addon_id);
                            item.selectedaddons[index] = { ...item.selectedaddons[index], quantity: addon.quantity, totalPrice: addon.totalPrice }
                            currentTotal = parseFloat(currentTotal) + parseFloat(addon.singlePrice);
                        }
                    })
                }
                // state.tax = parseFloat((currentTotal * (shop?.tax / 100)).toFixed(2));
            }
            state.shop_id = shop.id;
            state.totalAmount = parseFloat(currentTotal);
            // state.totalPayableAmount = parseFloat((currentTotal + state.tax).toFixed(2));
            state.totalPayableAmount = parseFloat((currentTotal).toFixed(2));
        },

        removeAddonsCart: (state, action) => {
            const { orderitems, totalAmount } = state;
            const { id, isRemoveAll, removeAddonId } = action.payload;
            // console.log(action)
            const item = orderitems.find((item) => item.id === id);
            let currentTotal = totalAmount;
            if (item) { 
                let totalAddonPrice = 0
                if (item.selectedaddons && item.selectedaddons.length > 0) {
                    item.selectedaddons.map((addon) => {
                        if (addon.addon_id == removeAddonId) {
                            if (isRemoveAll) {
                                currentTotal -= addon.totalPrice;
                                const index = item.selectedaddons.indexOf(addon);
                                item.selectedaddons.splice(index, 1);
                                if (item.selectedaddons.length === 0) {
                                    const itemIndex = orderitems.indexOf(item);
                                    orderitems.splice(itemIndex, 1);
                                    if (orderitems.length === 0) {
                                        currentTotal = 0;
                                    }
                                }
                                state.totalAmount = parseFloat(currentTotal);
                            } else {
                                addon.quantity -= 1;
                                totalAddonPrice += item.selectedaddons.reduce((totalAddonPrice, p) => {
                                    totalAddonPrice += parseFloat(p.singlePrice) * addon.quantity;
                                    return totalAddonPrice
                                }, 0);
                                addon.totalPrice = parseFloat(totalAddonPrice);
                                const index = item.selectedaddons.findIndex((p) => p.addon_id === removeAddonId);
                                item.selectedaddons[index] = {
                                    ...item.selectedaddons[index],
                                    quantity: addon.quantity,
                                    totalPrice: parseFloat(totalAddonPrice)
                                };
                                currentTotal -= parseFloat(addon.singlePrice);
                                if (addon.quantity === 0) { 
                                    item.selectedaddons.splice(index, 1);
                                    item.totalPrice -= addon.totalPrice;
                                    if (item.selectedaddons.length === 0) {
                                        const itemIndex = orderitems.indexOf(item);
                                        orderitems.splice(itemIndex, 1);
                                        if (orderitems.length === 0) {
                                            currentTotal = 0;
                                            state.store_id = [];
                                        }
                                    }
                                }
                            }
                        }
                    }) 
                }
                state.totalAmount = parseFloat(currentTotal);
                state.totalPayableAmount = parseFloat((currentTotal).toFixed(2));
            }
        },
        clearCart: (state) => {
            state.orderitems = [];
            state.totalAmount = 0;
            state.tax = 0;
            state.discount = 0;
            state.amountToBeReturned = 0;
            state.totalPayableAmount = 0;
            state.paymentMethod = "Cash";
            state.shop_id = null;
            state.appliedCoupon = [];
            state.shop_info = [];
            state.shop_tax = [];
            state.shop_distance = [];
            state.deliveryCharge = 0;
            state.compelete_order = [];
        },
        clearCoupon: (state) => {
            state.appliedCoupon = null;
            state.discount = 0;
        },
        updateTotalAmount: (state, action) => {
            state.totalAmount = action.payload;
        },

        setDiscount: (state, action) => {
            state.discount = action.payload;
        },
        setAmountToBeReturned: (state, action) => {
            state.amountToBeReturned = action.payload;
        },
        setPaymentMethod: (state, action) => {
            state.paymentMethod = action.payload;
        },
        setSingleItemDiscount: (state, action) => {
            const { orderitems, totalAmount, totalPayableAmount } = state;
            const { item_id, price } = action.payload;
            let currentTotal = totalAmount;
            const item = orderitems.find((item) => item.id === item_id);
            if (item) {
                let oldTotal = totalAmount;
                currentTotal = parseFloat(currentTotal) - parseFloat(item.price) + parseFloat(price);
                item.price = price;
                item.totalPrice = (price * item.quantity).toFixed(2);
                // alert(currentTotal)
                state.tax = parseFloat((currentTotal * 0.15).toFixed(2));
                state.totalPayableAmount = parseFloat(
                    (currentTotal + state.tax - state.discount).toFixed(2)
                );
            }
        },

        setWholeCartDiscount: (state, action) => {
            const { discountType, discountAmount } = action.payload;
            // const { discount } = state;
            if (discountType == "fixed") {
                state.totalPayableAmount = (state.totalAmount + state.tax) - discountAmount;
            } else {
                const toReduce = (
                    (state.totalPayableAmount * discountAmount) /
                    100
                ).toFixed(2);
                state.totalPayableAmount = (state.totalAmount + state.tax) - toReduce;
            }
            state.discount = discountAmount;
            // console.log(action.payload);
            // state.totalPayableAmount = action.payload;
        },

        setSelectedAddon: (state, action) => {
            const { orderitems, totalAmount } = state;
            const { item_id, selectedAddon } = action.payload;

            const item = orderitems.find((item) => item.id === item_id);

            if (item) {
                let price = item.price;

                if (item.selectedAddon && item.selectedAddon?.id != selectedAddon.id) {
                    price = parseFloat(price) - parseFloat(item.selectedAddon.price);
                    item.price = price;
                }
                item.selectedAddon = selectedAddon;

                item.price = (
                    parseFloat(price) + parseFloat(selectedAddon.price)
                ).toFixed(2);
            }
        },

        setSelectedModifier: (state, action) => {
            const { orderitems, totalAmount } = state;
            const { item_id, selectedModifier } = action.payload;

            const item = orderitems.find((item) => item.id === item_id);

            if (item) {
                let price = item.price;

                if (item.selectedModifier && item.selectedModifier?.id != selectedModifier.id) {
                    price = parseFloat(price) - parseFloat(item.selectedModifier.price);
                    item.price = price;
                }
                item.selectedModifier = selectedModifier;

                item.price = (
                    parseFloat(price) + parseFloat(selectedModifier.price)
                ).toFixed(2);
            }
        },
        addFromHoldCart: (state, action) => {
            const { data } = action.payload;
            state.orderitems = data.orderitems;
            state.totalAmount = data.totalAmount;
            state.tax = data.tax;
            state.discount = data.discount;
            state.amountToBeReturned = data.amountToBeReturned;
            state.totalPayableAmount = data.totalPayableAmount;
            state.paymentMethod = data.paymentMethod;
        },
    },

    extraReducers: {
        [applyCoupon.pending]: (state,) => {
            state.loading = true;
        },
        [applyCoupon.fulfilled]: (state, { payload }) => {
            state.loading = false;
            if (payload.success) {
                state.appliedCoupon = payload;
                state.discount = payload.used_amount;
            } else {
                state.appliedCoupon = null;
                state.discount = 0;
            }
            state.isSuccess = true;
        },
        [applyCoupon.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },

        [placeOrder.pending]: (state,) => {
            state.loading = true;
        },
        [placeOrder.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.compelete_order = payload;
            state.isSuccess = true;
        },
        [placeOrder.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },
        [getShopDistance.pending]: (state,) => {
            state.loading = true;
        },
        [getShopDistance.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.shop_distance = payload;
            state.isSuccess = true;
        },
        [getShopDistance.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },

        [getCartShop.pending]: (state,) => {
            state.loading = true;
        },
        [getCartShop.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.shop_info = payload;
            state.isSuccess = true;
        },
        [getCartShop.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },

        [checkCouponValid.pending]: (state,) => {
            state.loading = true;
        },
        [checkCouponValid.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.coupon_valid = payload;
            state.isSuccess = true;
        },
        [checkCouponValid.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },

        [calcualteShopMaxDistance.pending]: (state,) => {
            state.loading = true;
        },
        [calcualteShopMaxDistance.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.shop_distance = payload;
            if (payload?.shop_id?.delivery_charge_type === "DYNAMIC" && payload?.shop_id?.base_delivery_distance && payload?.shop_id?.extra_delivery_distance && payload?.shop_id?.extra_delivery_charge && payload?.shop_id?.base_delivery_charge) {
                const distanceFromUserToshop = payload.distance;
                if (distanceFromUserToshop > parseInt(payload?.shop_id?.base_delivery_distance)) {
                    const extraDistance = distanceFromUserToshop - parseInt(payload?.shop_id?.base_delivery_distance);
                    const extraCharge = (extraDistance / parseInt(payload?.shop_id?.extra_delivery_distance)) * parseFloat(payload?.shop_id?.extra_delivery_charge);
                    let dynamicDeliveryCharge = parseFloat(payload?.shop_id?.base_delivery_charge) + parseFloat(extraCharge);
                    dynamicDeliveryCharge = Math.ceil(dynamicDeliveryCharge);
                    state.deliveryCharge = dynamicDeliveryCharge;
                } else {
                    state.deliveryCharge = parseFloat(payload?.shop_id?.base_delivery_charge);
                }
            } else if (payload?.shop_id?.delivery_charge_type === "FIXED" && parseFloat(payload?.shop_id?.delivery_charge) > 0) {
                state.deliveryCharge = payload?.shop_id?.delivery_charge;
            } else if (payload?.shop_id?.zone?.delivery_charge_type === "DYNAMIC" && payload?.shop_id?.zone?.base_delivery_distance && payload?.shop_id?.zone?.extra_delivery_distance && payload?.shop_id?.zone?.extra_delivery_charge && payload?.shop_id?.zone?.base_delivery_charge) {
                const distanceFromUserToshop = payload.distance;
                if (distanceFromUserToshop > parseInt(payload?.shop_id?.zone?.base_delivery_distance)) {
                    const extraDistance = distanceFromUserToshop - parseInt(payload?.shop_id?.zone?.base_delivery_distance);
                    const extraCharge = (extraDistance / parseInt(payload?.shop_id?.zone?.extra_delivery_distance)) * parseFloat(payload?.shop_id?.zone?.extra_delivery_charge);
                    let dynamicDeliveryCharge = parseFloat(payload?.shop_id?.zone?.base_delivery_charge) + parseFloat(extraCharge);
                    dynamicDeliveryCharge = Math.ceil(dynamicDeliveryCharge);
                    state.deliveryCharge = dynamicDeliveryCharge;
                } else {
                    state.deliveryCharge = parseFloat(payload?.shop_id?.zone?.base_delivery_charge);
                }
            } else if (payload?.shop_id?.zone?.delivery_charge_type === "FIXED" && parseFloat(payload?.shop_id?.zone?.delivery_charge) > 0) {
                state.deliveryCharge = payload?.shop_id?.zone?.delivery_charge;
            }
            state.isSuccess = true;
        },
        [calcualteShopMaxDistance.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },

        [calculateShopChargeTax.pending]: (state,) => {
            state.loading = true;
        },
        [calculateShopChargeTax.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.shop_tax = payload;
            state.isSuccess = true;
        },
        [calculateShopChargeTax.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },
    }
});

export const {
    addToCart,
    addCustomizationCart,
    addAddonsCart,
    removeFromCart,
    removeAddonsCart,
    setDiscount,
    setAmountToBeReturned,
    setPaymentMethod,
    clearCart,
    clearCoupon,
    updateTotalAmount,
    setSelectedAddon,
    setSingleItemDiscount,
    setWholeCartDiscount,
    addFromHoldCart,
    setSelectedModifier
} = cartSlice.actions;

export default cartSlice.reducer;