import React from "react";
import { Link } from "react-router-dom";

function OtherSetting() {
  return (
    <div>
      {/* <!-- header start --> */}
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header-panel">
            <Link to={'/profile'}>
              <i className="ri-arrow-left-s-line"></i>
            </Link>
            <h2>Other-Setting</h2>
          </div>
        </div>
      </header>
      {/* <!-- header end --> */}

      {/* <!-- notification section start --> */}
      <section className="section-b-space">
        <div className="custom-container">
          <div className="accordion option-accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                >
                  Select Language
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse">
                <div className="accordion-body">
                  <div className="form-check">
                    <label className="form-check-label" for="radio1">
                      English
                    </label>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="radio1"
                      value="option1"
                      checked
                    />
                  </div>
                  <div className="form-check">
                    <label className="form-check-label" for="radio2">
                      Spanish
                    </label>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="radio2"
                      value="option2"
                    />
                  </div>
                  <div className="form-check">
                    <label className="form-check-label" for="radio3">
                      France
                    </label>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="radio3"
                      value="option3"
                    />
                  </div>
                  <div className="form-check">
                    <label className="form-check-label" for="radio4">
                      Portuguese
                    </label>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="radio4"
                      value="option4"
                    />
                  </div>
                  <div className="form-check">
                    <label className="form-check-label" for="radio5">
                      Russian
                    </label>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="radio5"
                      value="option5"
                    />
                  </div>
                  <div className="form-check">
                    <label className="form-check-label" for="radio6">
                      Chinese
                    </label>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="radio6"
                      value="option6"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion option-accordion mt-3" id="accordionExample1">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                >
                  Change Currency
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body currency">
                  <ul className="currency-listing">
                    <li>US Dollar</li>
                    <li>INR</li>
                    <li>Euro</li>
                    <li>Pound</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- notification section end --> */}
    </div>
  );
}

export default OtherSetting;
